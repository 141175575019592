import { useDispatch, useSelector } from 'react-redux';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core';
import FormTemporalUser from './FormTemporalUser';
import { AlertConfirm } from '../../from-library/alerts/AlertConfirm';

export const UserSelector = ({
  open, users, selected, currentSigners, setUsers, onSelect, onClose, allowExternal = false
}) => {

  const [checked, setChecked] = useState(selected);
  const [openExternalForm, setOpenExternalForm] = useState(false);
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [temporalSelected, setTemporalSelected] = useState(null);

  const handleChange = (user) => {
    const currentIndex = checked.map(e => e.id).indexOf(user.id);
    const newChecked = [...checked];

    if (!!user.temporal) {
      setTemporalSelected(user.id);
      setOpenConfirmAlert(true);
      return
    }

    if (currentIndex === -1) {
      newChecked.push(user);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  }

  const handleAddUsers = () => {
    onSelect(checked)
    onClose()
  }

  const handleSubmitNoSigners = (temporal) => {
    setUsers(prev => [...prev, temporal])
    const newChecked = [...checked, temporal];
    setChecked(newChecked);
    setOpenExternalForm(false)
  }

  return (
    <>
      <Dialog open={open}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
          <DialogTitle>Seleccionar Usuarios</DialogTitle>
          {
            allowExternal && (
              <div style={{ padding: '20px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => { setOpenExternalForm(true) }}
                >
                  Añadir Externo
                </Button>
              </div>
            )
          }
        </div>
        <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
          <List style={{ paddingTop: "0px" }}>
            {
              users.map((item, index) => (
                <ListItem
                  key={`${item.id}-${index}`}
                  onClick={() => handleChange(item)}
                  style={{ paddingTop: '0px', paddingBottom: "0px" }}
                >
                  <Checkbox
                    id={`check${item.id}`}
                    checked={checked.some(some => some.id === item.id)}
                  />
                  <ListItemText primary={item.name} />
                </ListItem>
              ))
            }
          </List>
          {
            users.length === 0 && (
              <div className="text-center mt-2">
                No hay usuarios con el permiso para liberar
              </div>
            )
          }
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-end" }} >
          <Button
            className="primary-text"
            color="primary"
            variant="outlined"
            onClick={onClose}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddUsers}
          >
            Aceptar
          </Button>

        </DialogActions>
      </Dialog>

      {
        openExternalForm && (
          <FormTemporalUser
            open={openExternalForm}
            usersSelected={selected}
            signersSelected={currentSigners}
            availableUsers={users}
            submitActions={handleSubmitNoSigners}
            onCancel={() => setOpenExternalForm(false)}
          />
        )
      }

      {
        openConfirmAlert && (
          <AlertConfirm
            open={openConfirmAlert}
            onCancel={() => setOpenConfirmAlert(false)}
            onConfirm={() => {
              setUsers(prev => prev.filter(el => el.id !== temporalSelected));
              setChecked(curr => curr.filter(el => el.id !== temporalSelected))
              setTemporalSelected(null);
              setOpenConfirmAlert(false);
            }}
            textContent="El usuario se eliminará de la lista. ¿Deseas continuar?"
          />
        )
      }

    </>
  )
}