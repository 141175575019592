
import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';


export const CheckBox = (props) => {

  const { checked, setChecked, style, label, disabled } = props;

  const handleChangeBox = () => {
    setChecked();
  };

  return (
    <FormControlLabel
      disabled={disabled}
      style={{ ...style }}
      value="end"
      control={<Checkbox color="primary" />}
      label={label}
      labelPlacement="end"
      checked={checked}
      onChange={handleChangeBox}
    />
  );
}




