import cancelationConstants from "../constants/cancelation.constants";


const initialState = {
  temporalUsersNotToSign: [],
  temporalUserToSign: (null),
  usersNotToSign: [],
  endorser: (null),
  endorsee: (null),
  usersToSign: [],
  allUsers: [],
  signers: [],
}

export const cancelationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === cancelationConstants.SAVE_ALL_USERS_CANCELATION) {
    return {
      ...state,
      allUsers: payload
    }
  }

  if (type === cancelationConstants.SAVE_AVAILABLE_USERS_NOT_TO_SIGN_CANCELATION) {
    return {
      ...state,
      usersNotToSign: payload
    }
  }

  if (type === cancelationConstants.SAVE_ENDORSER_CANCELATION) {
    const newSigners = state.usersToSign.filter(el => !el.endorser)

    return {
      ...state,
      endorser: payload,
      usersToSign: [payload, ...newSigners]
      // signers: [payload, ...newSigners]
    }
  }

  if (type === cancelationConstants.SAVE_ENDORSEE_CANCELATION) {
    const newSigners = state.usersToSign.filter(el => el.endorser)

    return {
      ...state,
      endorsee: payload,
      usersToSign: [...newSigners, payload]
      // signers: [...newSigners, payload]
    }
  }

  if (type === cancelationConstants.SAVE_SIGNERS_CANCELATION) {
    return {
      ...state,
      signers: payload,
      endorser: payload.find(el => el.endorser),
      endorsee: payload.find(el => !el.endorser),
    }
  }

  if (type === cancelationConstants.SAVE_USERS_TO_SIGN_CANCELATION) {
    return {
      ...state,
      usersToSign: payload,
      endorser: payload.find(el => el.endorser),
      endorsee: payload.find(el => !el.endorser),
    }
  }

  if (type === cancelationConstants.RESTART_CANCELATION) {
    return {
      temporalUsersNotToSign: [],
      temporalUserToSign: (null),
      usersNotToSign: [],
      endorser: (null),
      endorsee: (null),
      usersToSign: [],
      allUsers: [],
      signers: [],
    }
  }

  if (type === cancelationConstants.EDIT_SIGNER_CANCELATION) {

    const { endorsee, endorser, signers } = state;

    const query = item => {
      if (item.userId === payload.userId) {
        item.type = payload.type;
        item.stroke = payload.stroke;
        item.hasVerificationId = payload.hasVerificationId
      }
      return item;
    }

    return {
      ...state,
      endorser: payload.userId === endorser?.userId ? { ...endorser, ...payload } : endorser,
      endorsee: payload.userId === endorsee?.userId ? { ...endorsee, ...payload } : endorsee,
      signers: signers.map(query)
    }
  }

  return state;

}