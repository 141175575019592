import React from 'react'
import { Dialog, Button } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';

export const Alert = (props) => {
  const { open, title, onConfirm, type } = props
  return (
    <Dialog open={open} {...props} >
      <div className="p-4 text-center">
        <div>
          {
            type === 'success' &&
            <CheckCircleIcon className="text-success dialog-icon" />
          }

          {
            type === 'error' &&
            <ErrorIcon className="text-error dialog-icon" />
          }

          {
            type === 'info' &&
            <InfoIcon color='primary' fontSize='large' />
          }
        </div>

        <h4 style={{ whiteSpace: "pre-line" }}>{title}</h4>

        <div>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onConfirm && onConfirm()}
          >
            Aceptar
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

Alert.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onConfirm: PropTypes.func,
  type: PropTypes.string
}