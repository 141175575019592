import React from 'react';
import { Route, Switch } from 'react-router';

import Onboarding from '../../components/onboarding/OnBoarding';
import NewPassword from "../../components/onboarding/new-password";
import Home from '../../components/dashboard/home';
import { PrivateRoute } from '../../components/private-route';

const Routing = () => {
  return (
    <Switch>
      <PrivateRoute path='/dashboard' component={Home} />
      <Route path="/:clientKey/create-account/:userId" exact render={(props) => (
        <Onboarding {...props} createAccount={true} />
      )} />
      <Route path="/:clientKey/new-password/:token_restore" component={NewPassword} />
      <Route path="/:clientKey" exact component={Onboarding} />

      {/* Implementar componente cuando no existe la ruta */}
      <Route component={Onboarding} />
    </Switch>
  );
}

export default Routing;
