import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";


import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import { Button } from '@material-ui/core';

const optionsDate = {
  year: "numeric",
  month: "short",
  day: "numeric"
}

const activityType = {
  "revision": "Revisión",
  "firm": "Firma",
  "endorse": "Endoso",
  "cancel": "Cancelación",
  "textsigner": "Firma"
}

const descendingComparator = (a, b, orderBy) => (b[orderBy]?.toString() ?? "").localeCompare(a[orderBy]?.toString() ?? "");

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    headCells
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            size={'small'}
            sortDirection={headCell.id !== "detail" && (orderBy === headCell.id ? order : false)}
          >
            <TableSortLabel
              className={classes.tableTitles}
              active={headCell.id !== "detail" && (orderBy === headCell.id)}
              direction={(orderBy === headCell.id ? order : "asc")}
              onClick={headCell.id !== "detail" ? createSortHandler(headCell.id) : () => { }}
            >
              {headCell.label}
              {headCell.id !== "detail" ? (orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  table: {
    minWidth: 750
  },
  container: {
    maxHeight: 440,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableTitles: {
    fontWeight: 'bold'
  }
}));

export const TableInformation = ({ headCells = [], data = [], handleClickDetail }) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("vault");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [showData, setShowData] = useState([]);
  const [rows, setRows] = useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDisplayRows = ({ count, page }) => {
    const currentPage = page + 1;
    const totalPages = Math.ceil(count / rowsPerPage)
    return `Página ${currentPage} de ${totalPages}`
  }

  useEffect(() => {
    setRows(showData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [page, rowsPerPage, showData])

  useEffect(() => {
    setShowData((prevData) => stableSort(prevData, getComparator(order, orderBy)));
  }, [order, orderBy])

  useEffect(() => {
    setShowData(data);
    setPage(0);
    setRows(data.slice(0, rowsPerPage));
  }, [data])


  return (
    <div>
      <Paper>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={showData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={"Documentos por página"}
          nextIconButtonText={"siguiente"}
          backIconButtonText={"anterior"}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelDisplayedRows={handleDisplayRows}
        />
        <TableContainer className={classes.container}>
          <Table
            stickyHeader
            size={"small"}
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={showData.length}
              headCells={headCells}
            />

            <TableBody>
              {
                rows.map((row, i) => {
                  const createDate = new Date(`${row.creationDate} GMT-6`);
                  return (
                    <TableRow hover key={row.vault + i + Date.now()}>
                      <TableCell align="left">{row.vault}</TableCell>
                      <TableCell align="left">{activityType[row.activity]}</TableCell>
                      <TableCell align="left">{createDate.toLocaleDateString('es-Mexico', optionsDate)}</TableCell>
                      <TableCell align="left">{row.creator}</TableCell>
                      <TableCell align="left">{row.documentName}</TableCell>
                      <TableCell align="center">{`${row.pending} / ${row.total}`}</TableCell>
                      <TableCell align="left">
                        {
                          row.involved ? (
                            <div className="circle-check is-sign">
                              <em className="fa fa-check"></em>
                            </div>
                          ) : null
                        }
                      </TableCell>

                      <TableCell align="center">{
                        <span style={{ color: 'red', fontWeight: 'bold' }}>{row.expired}</span>

                      }</TableCell>

                      <TableCell align="left">
                        <Button
                          variant="contained"
                          color='primary'
                          onClick={() => handleClickDetail(row)}
                        >
                          Detalle
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>

      </Paper>
    </div>
  )
}
