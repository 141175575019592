import { getCurrentUser } from '../core/helpers'

export default {
  users: {
    users: [],
    user: null,
    usersFiltered: [],
    editing: false,
    creating: false,
    deleting: false,
    blocking: false,
    fetchingUsers: false,
    roles: [],
    companies: [],
    fetchingCompanies: false,
    currentPage: 1,
    totalPages: 1,
    includeEmail: false,
  },
  templates: [],
  session: {
    loggedIn: !!sessionStorage.access_token,
    keep_active_session: false,
    currentUser: getCurrentUser(),
  },
  alert: {},
  permissions: {
    permission: []
  },
  subfolderId: null,
  companies: null
}