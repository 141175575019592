import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import { AlertConfirm } from '../../../from-library/alerts/AlertConfirm';
import { addToGroupActions } from '../../../../core/actions/addToGroup.actions';
import { FormTemporalUsersGroup } from '../../../team/users/FormTemporalUsersGroup';

export const AddMultipleUsersToSignGroup = () => {
  const dispatch = useDispatch();

  const availableUsers = useSelector(state => state.addToGroupReducer.available_users);
  const preparedSigners = useSelector(state => state.addToGroupReducer.prepared_signers);

  const [checked, setChecked] = useState(preparedSigners);
  const [openList, setOpenList] = useState(false);
  const [openExternalForm, setOpenExternalForm] = useState(false);
  const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
  const [temporalSelected, setTemporalSelected] = useState(null);

  const handleChange = (user) => {
    const currentIndex = checked.map(e => e.userId).indexOf(user.userId);
    const newChecked = [...checked];

    if (!!user.temporal) {
      setTemporalSelected(user.userId);
      setOpenConfirmAlert(true);
      return
    }

    if (currentIndex === -1) {
      newChecked.push(user);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  }

  const handleAddUsers = () => {
    dispatch(addToGroupActions.saveSigners(checked));
    setOpenList(false);
  }

  const handleCancel = () => {
    setOpenList(false);
    setChecked(preparedSigners);
  }

  const handleSubmitExternalUser = (user) => {
    dispatch(addToGroupActions.saveSigners([...preparedSigners, user]));
    setChecked(ch => [...ch, user]);
    setOpenExternalForm(false);
  }

  return (<>
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpenList(true)}
      >
        Agregar
      </Button>
    </div>

    {
      openList && (
        <Dialog open={openList}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
            <DialogTitle>Seleccionar Usuarios</DialogTitle>


            <div style={{ padding: '20px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => { setOpenExternalForm(true) }}
              >
                Añadir Externo
              </Button>
            </div>


          </div>
          <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
            <List style={{ paddingTop: "0px" }}>
              {
                [...availableUsers, ...preparedSigners.filter(el => el.temporal)].map((item, index) => (
                  <ListItem
                    key={`${item.userId}-${index}`}
                    onClick={() => handleChange(item)}
                    style={{ paddingTop: '0px', paddingBottom: "0px" }}
                  >
                    <Checkbox
                      id={`check${item.userId}`}
                      checked={checked.some(some => some.userId === item.userId)}
                    />
                    <ListItemText primary={!!item.temporal ? `${item.name} (externo 1 vez)` : item.name} />
                  </ListItem>
                ))
              }
            </List>
          </DialogContent>
          <DialogActions style={{ justifyContent: "flex-end" }} >
            <Button
              className="primary-text"
              color="primary"
              variant="outlined"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddUsers}
              disabled={checked.length === 0}
            >
              Aceptar
            </Button>

          </DialogActions>
        </Dialog>
      )
    }

    {
      openExternalForm && (
        <FormTemporalUsersGroup
          open={openExternalForm}
          onCancel={() => setOpenExternalForm(false)}
          submitActions={handleSubmitExternalUser}
          preparedSigners={preparedSigners}
          availableUsers={availableUsers}
        />
      )
    }

    {
      openConfirmAlert && (
        <AlertConfirm
          open={openConfirmAlert}
          onCancel={() => setOpenConfirmAlert(false)}
          onConfirm={() => {
            dispatch(addToGroupActions.saveSigners(preparedSigners.filter(el => el.userId !== temporalSelected)));
            setChecked(curr => curr.filter(el => el.userId !== temporalSelected))
            setTemporalSelected(null);
            setOpenConfirmAlert(false);
          }}
          textContent="El usuario se eliminará de la lista. ¿Deseas continuar?"
        />
      )
    }
  </>)
}
