import React, { useEffect, useState } from 'react';
import Header from '../dashboard/header';
import {
  Grid,
  IconButton,
  Breadcrumbs,
  Box,
} from '@material-ui/core';
import EventIcon from '@material-ui/icons/Event';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AlertInfo from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import './vault.scss';
import { TableFolder } from '../from-library/table-folder/TableFolder';
import { getVaultsUser } from '../../core/http/functionRequests';
import { SearchBarVaults } from './components/SearchBarVaults';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, vaultActions } from '../../core/actions';

const headCells = ['Nombre', 'Autor', 'Última modificación'];

export const VaultFn = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const [fileSelected, setFileSelected] = useState(null);
  const items = useSelector(state => state.vaultReducer.searchedItemsLocal);
  const loading = useSelector(state => state.vaultReducer.loadingItems);

  const getVaults = async () => {
    try {
      dispatch(vaultActions.loadingItems())
      const vaults = await getVaultsUser()

      dispatch(vaultActions.saveItems(vaults.data))
    } catch (error) {
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
      console.error(error);
    }
  }

  const searchLocal = (input) => {
    dispatch(vaultActions.searchItemsLocal(input))
  }

  const handleDocumentSelect = (id) => {
    // setFileSelected({ id })
  }

  useEffect(() => {
    getVaults()
  }, [])

  return (
    <React.Fragment>
      <Header
        titleRight={'Bóveda'}
        actions={[
          <IconButton variant='outlined'>
            <NotificationsIcon color='primary' />
          </IconButton>,
          <IconButton variant='outlined'>
            <EventIcon color='primary' />
          </IconButton>,
        ]}
      />
      <Box className='animate__animated animate__fadeIn'>
        <Grid container alignItems='center'>
          <Grid item xs={12} >
            {
              !loading && (
                <SearchBarVaults
                  searchlocal={searchLocal}
                  isVaultView={true}
                  documentselect={handleDocumentSelect}
                />
              )
            }
          </Grid>
        </Grid>

        <Grid container className='Breadcrumbs'>
          <Breadcrumbs
            separator={<ArrowForwardIosIcon />}
            aria-label='breadcrumb'
          >
            <span>Bóvedas</span>
          </Breadcrumbs>
        </Grid>

        {loading && (
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity='info'>Cargando carpetas...</AlertInfo>
          </Grid>
        )}

        {!loading && items.length === 0 && (
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity='info'>Sin datos para mostrar</AlertInfo>
          </Grid>
        )}

        {
          !loading && items.length > 0 ? (
            <Grid
              container
              style={{ width: '100%' }}
              className='animate__animated animate__fadeIn'
            >
              <Grid item xs={12}>
                <TableFolder
                  files={items}
                  isVaults
                  headCells={headCells}
                  onClickItem={(vault) => setFileSelected(vault)}
                  itemSelected={fileSelected}
                  onDoubleClick={(evt, vault) => history.push(`/dashboard/vault/${vault.id}`)}
                />
              </Grid>
            </Grid>
          ) : <></>
        }

      </Box>
    </React.Fragment>
  )
}
