import { authHeader, axiosRequestBlockChain } from "../../../configs/axios.config";
import { sortData } from "../../utils";



export const foldersCompany = async (companyId, companyName) => {
  try {
    const { data } = await axiosRequestBlockChain.get(`/folder/${companyId}`, { headers: authHeader() });

    const filtered = data.subfolders.filter(el => el.name !== "Trash");
    const folders = sortData(filtered.map(item => ({
      id: item._id,
      name: item.name,
      type: 'folder',
      isFolder: true,
      onlyExistInMongo: !!item.onlyExistInMongo,
      onlyExistInKaleido: !!item.onlyExistInKaleido,
      path: item.name,
      pathLabel: `${companyName}/${item.name}`,
      children: [],
      childrenLoaded: false,
    })));

    return folders;

  } catch (error) {
    console.error(error);
    throw { msg: "Error obteniendo folders de la bóveda" }
  }
}

export const getFolderInfo = async (folderId, companyName) => {
  try {
    const { data } = await axiosRequestBlockChain.get(`/folder/details/${folderId}`, { headers: authHeader() });

    const subfolders = sortData(data.subfolders.map(item => (
      {
        id: item._id,
        name: item.name,
        type: 'folder',
        isFolder: true,
        onlyExistInMongo: !!item.onlyExistInMongo,
        onlyExistInKaleido: !!item.onlyExistInKaleido,
        path: `${item.path}/${item.name}`,
        pathLabel: `${companyName}/${item.path}/${item.name}`,
        children: [],
        childrenLoaded: false,
      }
    )));

    return subfolders;
  } catch (error) {
    console.error(error);
    throw error
  }
}

export const getAllDocumentsFromFolder = async (folderId) => {
  try {
    const { data } = await axiosRequestBlockChain.get(`/folder/total-documents/${folderId}`, { headers: authHeader() });
    return data;
  } catch (error) {
    throw error
  }
}

export const moveFolder = async (folderId, body) => {
  try {

    const { data } = await axiosRequestBlockChain.post(`/folder/move/${folderId}`, body, { headers: authHeader() });

    return data;

  } catch (error) {
    console.error(error.response.data);
    throw error
  }
}