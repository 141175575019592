import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CardUserFn } from "./CardUserFn";
import { UserHttp } from "../../../../core/http/user.http";
import { tokenExpired } from "../../../../core/utils";
import { authActions } from "../../../../core/actions";
import { addToGroupActions } from "../../../../core/actions/addToGroup.actions";
import { AddMultipleUsersToSignGroup } from "./AddMultipleUsersToSignGroup";
import "./popup-user.scss";

export const PopupGroupSign = ({ open, onCancel, onSubmit, labelType = "firm", documentId }) => {

  const dispatch = useDispatch();

  const preparedSigners = useSelector(state => state.addToGroupReducer.prepared_signers);
  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);

  const onChangeUser = (user) => {
    dispatch(addToGroupActions.editSigner({
      userId: user.userId,
      type: user.type,
      stroke: user.stroke
    }))
  }

  const handleCancel = () => {
    onCancel();
  }

  const handleSubmit = () => {
    dispatch(addToGroupActions.saveFinalSigners())
    onSubmit()
  }

  useEffect(() => {
    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }

    const userHttp = new UserHttp();

    setLoad(true);
    userHttp.findAllUsersByDocumentId(
      documentId,
      "firm",
      (resp) => {
        const sortUsersName = (x, y) => {
          return x.name.localeCompare(y.name);
        };
        let s = resp.sort(sortUsersName);
        dispatch(addToGroupActions.saveUsersCanSign(s));
        setLoad(false);
      },
      (error) => {
        setLoad(false);
        setError(true);
      }
    );
    // eslint-disable-next-line 
  }, []);

  return (
    <Dialog className="popup-user" open={open}>
      <div
        style={{
          display: 'flex',
          flexDirection: "column"
        }}
      >

        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "10px"
          }}
        >
          <DialogTitle>Firmantes seleccionados</DialogTitle>
          {!load && (<AddMultipleUsersToSignGroup />)}
        </div>
      </div>

      {error && (
        <DialogContent>
          <Alert severity="error">
            Hubo un problema al cargar lo usuarios inténtalo mas tarde
          </Alert>
        </DialogContent>
      )}

      {load ? (
        <DialogContent className="text-center">
          <CircularProgress />
        </DialogContent>
      ) : (
        <>
          {!error && (
            <>
              <DialogContent className="card-zone">
                <Grid
                  container
                  spacing={1}
                  direction="column"
                >
                  {
                    preparedSigners.map((item, index) => (
                      <CardUserFn
                        key={item.userId}
                        labelType={labelType}
                        onlyShow={false}
                        id={item.userId}
                        name={item.name}
                        email={item.email}
                        color={item.color}
                        type={item.type}
                        rfc={item.rfc}
                        stroke={item.stroke}
                        useRFC={item.temporal ? !!item.rfc : item.hasRFC}
                        onChange={el => onChangeUser(el)}
                      />
                    ))
                  }
                </Grid>

                {preparedSigners.length === 0 && (
                  <div className="text-center mt-2">
                    No hay firmantes seleccionados
                  </div>
                )}
              </DialogContent>
            </>
          )}
        </>
      )}
      <div className={`bottom-options`}>
        <DialogActions style={{ padding: "15px" }}>
          <Button
            className="primary-text"
            color="primary"
            variant="outlined"
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>

          <Button
            color="primary"
            variant="contained"
            disabled={preparedSigners.length === 0}
            onClick={() => handleSubmit()}
          >
            Aceptar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}
