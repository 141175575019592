import React from 'react';
import { AvatarUsers } from 'axeleratum-sgc-frontend-library';
import { Grid, Typography } from '@material-ui/core';
import { convertBytes } from '../../../core/helpers';

const DetailsContent = props => {
  const { classes, dataElement, accessElements } = props;

  const initialStates = [
    "Preparar Revisión",
    "En Revisión",
    "Aprobado",
    "Sin información",
    "Para enviar a firma en grupo"
  ]

  return (
    <React.Fragment>
      <Grid item xs={12} >
        <Grid container wrap="nowrap">
          <Grid item xs={4}>
            <Typography color="primary">
              Acceso
            </Typography>
          </Grid>
          <Grid item xs={8} >
            <Grid container>
              <AvatarUsers users={accessElements} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Grid container wrap="nowrap" >
          <Grid item xs={4}>
            <Typography color="primary">
              Subido por
            </Typography>
          </Grid>
          <Grid item xs={8} >
            <Typography className={classes.textDescription}>
              {(dataElement && dataElement.author) ? dataElement.author : 'Sin información'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      {dataElement && !dataElement.isFolder && (
        <React.Fragment>
          <Grid item xs={12}>
            <Grid container >
              <Grid item xs={4}>
                <Typography color="primary">
                  Archivo
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography className={classes.textDescription}>
                  {dataElement && dataElement.extension ? dataElement.extension.split('.')[1].toUpperCase()
                    : 'Sin información'
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item md={12}>
            <Grid container wrap="nowrap" >
              <Grid item xs={4}>
                <Typography color="primary">
                  Hash
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography className={classes.textDescription}>
                  {dataElement && dataElement.hash ? dataElement.hash : 'Sin información'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container wrap="nowrap" >
              <Grid item xs={4}>
                <Typography color="primary">
                  Descripción
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography className={classes.textDescription}>
                  {dataElement?.description ?? ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container wrap="nowrap">
              <Grid item xs={4}>
                <Typography color="primary">
                  Tamaño
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography className={classes.textDescription}>
                  {dataElement && dataElement.size ? convertBytes(dataElement.size) : ''}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container wrap="nowrap">
              <Grid item xs={4}>
                <Typography color="primary">
                  Estatus
                </Typography>
              </Grid>
              <Grid item xs={8} >
                <Typography className={classes.textDescription}>
                  {dataElement && dataElement?.statusBpm}
                </Typography>
                <Typography className={classes.textDescription}>
                  {
                    initialStates.includes(dataElement?.statusBpm)
                      ? ''
                      : dataElement.hasNom151 ? '' : 'Sin NOM-151'
                  }
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}

    </React.Fragment>
  )
}

export default DetailsContent