import { addToGroupConstants } from "../constants";

const initialState = {
  available_users: [],
  prepared_signers: [],
  signers: [],
  stickers: [],
}

export const addToGroupReducer = (state = initialState, action) => {
  const { type, payload } = action;

  if (type === addToGroupConstants.USERS_ABLE_TO_SIGN_GROUP) {
    return {
      ...state,
      available_users: payload
    }
  }

  if (type === addToGroupConstants.SAVE_SIGNERS_GROUP) {
    return {
      ...state,
      prepared_signers: payload,
    }
  }

  if (type === addToGroupConstants.SAVE_SIGNERS_STICKERS_GROUP) {
    return {
      ...state,
      stickers: payload
    }
  }

  if (type === addToGroupConstants.EDIT_PREPARED_SIGNER_GROUP) {
    const newArray = item => {
      if (item.userId === payload.userId) {
        item.type = payload.type;
        item.stroke = payload.stroke;
      }
      return item;
    }
    return {
      ...state,
      prepared_signers: state.prepared_signers.map(newArray)
    }
  }

  if (type === addToGroupConstants.SAVE_FINAL_SIGNERS_GROUP) {
    let stickersValue = []
    if (state.stickers.length > 0) {
      stickersValue = state.stickers.reduce((acc, curr) => {
        const existUser = state.prepared_signers.find(el => el.userId === curr.userId);
        if (existUser) {
          return [...acc, curr]
        }
        return acc;
      }, [])
    }
    return {
      ...state,
      signers: state.prepared_signers,
      stickers: stickersValue
    }
  }

  if (type === addToGroupConstants.LOAD_PREPARATION_GROUP) {
    const { signers, stickers } = payload;
    return {
      ...state,
      prepared_signers: signers,
      signers,
      stickers,
    }
  }

  if (type === addToGroupConstants.RESTART_GROUP) {
    return initialState
  }

  return state
}