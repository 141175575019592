import React, { Component } from 'react';
import Header from '../dashboard/header';
import { Button, IconButton, Grid, TextField, InputAdornment, Menu, MenuItem, InputLabel, Select, Box } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EventIcon from '@material-ui/icons/Event';
import SearchIcon from '@material-ui/icons/Search';
import AlertInfo from "@material-ui/lab/Alert";
import { FilterIcon } from 'axeleratum-sgc-frontend-library';
import { BusinessHttp } from "../../core/http/business.http";
import './templates.scss';
import TemplateList from './template-list';
import { TemplateBusinessCard } from '../from-library/templates/TemplateBusinessCard';
import { getVaultsUserDashboard } from '../../core/http/functionRequests';
import { tokenExpired } from '../../core/utils';
import { connect } from 'react-redux';
import { authActions } from '../../core/actions';

class Templates extends Component {

  businessHttp = new BusinessHttp();

  constructor(props) {
    super(props);

    this.state = {
      businessList: null,
      businessListFiltered: null,
      business: null,
      anchorEl: null,
      valueSelectColumn: 'name',
      valueSelectSort: 'a-z',
      querySearch: ''
    }
  }

  componentDidMount() {
    this.findAllBusiness();
  }



  findAllBusiness() {
    const { userLoggedOut } = this.props;

    getVaultsUserDashboard()
      .then((resp) => {
        this.setState({ businessList: resp.data, businessListFiltered: resp.data });
      }).catch(error => {
        if (error.response.status === 401) {
          userLoggedOut()
        }
      })

  }

  handleSearch = (query) => {
    const { businessList, valueSelectSort } = this.state;
    const businessFiltered = businessList.filter((business) =>
      business.name.toLowerCase().includes(query.toLowerCase())
    );

    if (valueSelectSort === 'z-a') {
      businessFiltered.sort((a, b) => {
        const nameA = a.name;
        const nameB = b.name;

        return nameB > nameA ? 1 : nameB < nameA ? -1 : 0;
      });
    }

    this.setState({ businessListFiltered: businessFiltered });
  }

  handleSort = (evt) => {
    this.setState({ anchorEl: evt.currentTarget })
  }

  onChangeSelect = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value })
  }

  renderBusinessList() {
    const businessListFiltered = this.state.businessListFiltered;

    return (
      <React.Fragment>
        <Box variant="subtitle1" fontWeight="fontWeightBold" mt={3} mb={3} pl={1}>
          <h4>Bóvedas</h4>
        </Box>

        {
          !businessListFiltered &&
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity="info">Cargando bóvedas...</AlertInfo>
          </Grid>
        }

        {
          businessListFiltered && businessListFiltered.length === 0 &&
          <Grid container style={{ justifyContent: 'center', width: '100%' }}>
            <AlertInfo severity="info">No hay bóvedas registradas</AlertInfo>
          </Grid>
        }

        {
          (businessListFiltered && businessListFiltered.length > 0) &&
          <Grid className="animate__animated animate__fadeIn" container direction="row" spacing={2}>
            {
              businessListFiltered.map((business, index) => {
                return (
                  <Grid key={`business-${index}`} item xs={12} sm={6} md={4} lg={3}>
                    <TemplateBusinessCard
                      label={business.name}
                      value={business.id}
                      colorIndex={index > 8 ? (index % 8) : index}
                      onClick={(data) => {
                        const { isLoggedIn, userLoggedOut } = this.props;
                        if (isLoggedIn && tokenExpired()) {
                          userLoggedOut()
                          return
                        }
                        this.setState({ business: business })
                      }}
                    />
                  </Grid>
                )
              })
            }
          </Grid>
        }
      </React.Fragment>
    );
  }

  render() {
    const {
      business,
      anchorEl,
      valueSelectColumn,
      valueSelectSort
    } = this.state;


    return (
      <React.Fragment>
        <Header
          titleRight="Plantillas"
          actions={[
            <Button variant="outlined">
              Exportar
            </Button>,
            <IconButton variant="outlined">
              <NotificationsIcon color="primary" />
            </IconButton>,
            <IconButton variant="outlined">
              <EventIcon color="primary" />
            </IconButton>,
          ]}
        />

        {
          !business &&
          <Box>
            <Grid container>
              <Grid item xs>
                <TextField
                  label="¿Qué buscas?"
                  variant="outlined"
                  onChange={(evt) => {
                    this.setState({ querySearch: evt.target.value })
                    this.handleSearch(evt.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  margin="dense"
                  fullWidth
                ></TextField>
              </Grid>
              <Grid className="text-right" item xs={2} sm={1}>
                <IconButton variant="outlined" color="secondary" onClick={(evt) => this.handleSort(evt)}>
                  <FilterIcon color="primary" />
                </IconButton>
              </Grid>
            </Grid>
            <Menu
              id="simple-menu-filter-template"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => this.setState({ anchorEl: null })}
            >
              <Box p={2}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Grid container item xs={12} direction="column">
                      <InputLabel>Nombre</InputLabel>
                      <Select
                        onChange={(selectedData) => this.onChangeSelect(selectedData)}
                        value={valueSelectColumn}
                        name='valueSelectColumn'
                      >
                        <MenuItem value="">
                          <em>Todos</em>
                        </MenuItem>

                        <MenuItem value={'name'}>Nombre</MenuItem>
                      </Select>
                    </Grid>
                    <Grid container item xs={12} direction="column">
                      <InputLabel >Criterio de ordenación</InputLabel>
                      <Select
                        onChange={(selectedData) => { this.onChangeSelect(selectedData) }}
                        value={valueSelectSort}
                        name='valueSelectSort'
                        className={'selectColumn'}
                      >
                        <MenuItem value={`a-z`}>A a Z</MenuItem>
                        <MenuItem value={`z-a`}>Z a A</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <div className="text-center">
                      <Button
                        type="button"
                        variant="outlined"
                        color="primary"
                        onClick={() => this.setState({
                          anchorEl: null,
                        })
                        }
                      >
                        Cancelar
                      </Button>

                      <Button
                        className="ml-2"
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          this.setState({ anchorEl: null })
                          this.handleSearch(this.state.querySearch)
                        }
                        }
                      >
                        Aceptar
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Menu>
          </Box>
        }

        {
          !business &&
          <Box mb={3}>{this.renderBusinessList()}</Box>
        }

        {
          business &&
          <TemplateList business={business} />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.authReducer.loggedIn,
  }
}

const mapDispatchToProps = {
  userLoggedOut: authActions.userLoggedOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
