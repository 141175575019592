import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { uuid } from 'axeleratum-sgc-frontend-library';
import React from 'react'

export const TableData = (props) => {
  const { values, containerStyles } = props;

  return (
    <TableContainer component={Paper} style={containerStyles}>
      <Table size='small'>
        <TableBody>
          {
            values.map((row, i) => (
              <TableRow key={uuid() + i}>
                {row.map(cell => (
                  <TableCell
                    title={cell.label}
                    key={uuid()}
                    style={cell.style}
                  >
                    {cell.label.length > 40 ? cell.label.slice(0, 37) + "..." : cell.label}
                  </TableCell>
                ))}
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}
