//React
import { useEffect, useState } from "react";
//Material UI
import { Button, DialogTitle, Grid, Tab } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import React from "react";
import Backdrop from "@material-ui/core/Backdrop";
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import CircularProgress from "@material-ui/core/CircularProgress";
import { uuid } from "axeleratum-sgc-frontend-library";
import { makeStyles, withStyles } from "@material-ui/core/styles";
//Componentes
import { Alert } from "../../from-library/alerts/Alert";
import { AlertConfirm } from "../../from-library/alerts/AlertConfirm";
import { FormModifiedSigners } from "../../team/users/FormModifiedSigners";
import {
	sendSignBlock,
	updateSigner,
} from "../../../core/http/functionRequests";
import { getCurrentUser } from "../../../core/helpers";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../../core/actions";
import { NotificationHttp } from "../../../core/http/notification.http";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	root: {
		minWidth: "650px !important"
	},
	titleSigners: {
		paddingLeft: "46px",
		paddingBottom: "0px",
	},
	userLogo: {
		// paddingLeft: "46px !important",
	},
	divider: {
		padding: "0px 12px 0px 12px",
	},
	pendingSigners: {
		width: "auto",
		height: "450px",
		overflowY: "auto",
		overflowX: "hidden",
	},
	signersRejected: {
		width: "600px",
		height: "450px",
		overflowY: "auto",
		overflowX: "hidden",
	},
	completedSigners: {
		width: "500px",
		height: "450px",
		overflowY: "auto",
		overflowX: "hidden",
	},
	footer: {
		marginBottom: "0.6rem",
	},
	rejected: {
		color: "red",
		fontWeight: "bold"
	},
	reasonTitle: {
		fontWeight: '700'
	},
	idVerified: {
		color: "green",
		fontWeight: "bold"
	}
}));
const HtmlTooltip = withStyles((theme) => ({
	tooltip: {
		backgroundColor: '#f5f5f9',
		fontSize: theme.typography.pxToRem(12),
		border: '1px solid #000000',
	},
}))(Tooltip);


export const ReminderFunc = (props) => {
	const classes = useStyles();
	const notificationHttp = new NotificationHttp();

	const LINK = "link";
	const {
		onClose,
		hasOrder,
		canceled,
		users,
		hasNom151,
		documentId,
		activity,
		expired,
	} = props;

	const [signers, setSigners] = useState(users);
	const [selectedTab, setSelectedTab] = useState("1");
	const [signed, setSigned] = useState([]);
	const [notSigned, setNotSigned] = useState([]);
	const [openAlert, setOpenAlert] = useState(false);
	const [message, setMessage] = useState("");
	const [typeMessage, setTypeMessage] = useState("");
	const [openAlertConfirm, setOpenAlertConfirm] = useState(false);
	const [textConfirm, setTextConfirm] = useState("");
	const [userSelected, setUserSelected] = useState(null);
	const [openForm, setOpenForm] = useState(false);
	const [dataForm, setDataForm] = useState(null);
	const [openLoader, setOpenLoader] = useState(false);
	const [allowModify, setAllowModify] = useState(false);
	const [handleTypeConfirm, setHandleTypeConfirm] = useState(null);
	const [showBlockButton, setShowBlockButton] = useState(false);
	const [userId, setUserId] = useState("");
	const [isRejected, setIsRejected] = useState(false)

	const dispatch = useDispatch();

	const formatText = (text) => {
		if (!text) {
			return "";
		}
		if (text.length > 20) {
			return `${text.substring(0, 17)}...`;
		}
		return text;
	};

	const formatTextSigned = (text) => {
		if (!text) {
			return "";
		}
		if (text.length > 30) {
			return `${text.substring(0, 27)}...`;
		}
		return text;
	};

	const splitUsers = () => {
		const signed = [];
		const notSigned = [];

		if (signers) {
			signers.forEach((element) => {
				if (element.signed || element.rejected) {
					signed.push(element);
					if (element.rejected) setIsRejected(true)
				} else {
					notSigned.push(element);
				}
			});
		}
		setSigned(signed);
		setNotSigned(notSigned);
	};

	const handleConfirmCancel = () => {
		setOpenAlertConfirm(false);
		setHandleTypeConfirm(null);
	};

	const requestReminder = (userId) => {
		notificationHttp
			.signReminder({
				documentId,
				activityId: activity.id,
				receiverId: userSelected ?? userId,
			})
			.then(() => {
				setOpenAlert(true);
				setMessage("Tu recordatorio ha sido enviado con exito");
				setTypeMessage("success");
			})
			.catch((error) => {
				if (error.response.status === 401) {
					dispatch(authActions.userLoggedOut())
				}
				setOpenAlert(true);
				setMessage(
					"Tuvimos problemas para enviar el recordatorio intentalo mas tarde"
				);
				setTypeMessage("error");
			});
	};

	const requestBlockSignatures = async () => {
		try {
			await sendSignBlock({ receiverId: userId });
			setMessage("Correo enviado");
			setTypeMessage("success");
		} catch (error) {
			console.log(error);
			if (error.response.status === 401) {
				dispatch(authActions.userLoggedOut())
			}
			const msg =
				typeof error === "object"
					? error[0].msg
					: typeof error === "string"
						? error
						: "error enviando correo.";
			setMessage(msg);
			setTypeMessage("error");
		} finally {
			setOpenAlert(true);
			setUserId("");
		}
	};

	const handleConfirm = () => {
		handleConfirmCancel();
		if (handleTypeConfirm === "reminder") {
			requestReminder();
		}
		if (handleTypeConfirm === "block") {
			requestBlockSignatures();
		}
	};

	const signReminder = (receiverId) => {
		setUserSelected(receiverId);

		const totalLinkSigners = signers.filter(
			(el) => el.signType === LINK && !el.signed
		);

		const signer = totalLinkSigners.find((el) => el.userId === receiverId);

		if (!hasOrder && totalLinkSigners.length > 1 && signer) {
			setHandleTypeConfirm("reminder");
			setTextConfirm(
				`Se enviaran recordatorios a todos los firmantes que no hayan todavia firmado y que tengan el mismo metodo de Liga por correo`
			);
			setOpenAlertConfirm(true);
		} else {
			requestReminder(receiverId);
		}
	};

	const handleModify = (item) => {
		setDataForm(item);
		setOpenForm(true);
	};

	const handleClose = () => {
		setOpenForm(false);
		setDataForm(null);
	};

	const updateSignerRequest = async (formData) => {
		try {
			setOpenLoader(true);
			await updateSigner(formData);


			setSigners((prevSigners) =>
				prevSigners.map((signer) => {
					if (signer.id === formData.id) {
						return {
							...signer,
							signType: formData.authority,
							...formData,
						};
					} else {
						return signer;
					}
				})
			);
			setOpenAlert(true);
			setMessage("Modificación realizada");
			setTypeMessage("success");
			setDataForm(null);
		} catch (error) {
			console.error(error.response);
			const msg = error.response.data.error
			setOpenForm(true);
			setOpenAlert(true);
			setMessage(msg ?? "Error actualizando firmante");
			setTypeMessage("error");
		} finally {
			setOpenLoader(false);
		}
	};

	const handleSubmit = (formData) => {
		setOpenForm(false);
		updateSignerRequest(formData);
	};

	const buttonReminder = (item) => (
		!(activity.type === "firm" && activity.firmamexSetId) &&
		!expired &&
		<Button
			variant="contained"
			color="primary"
			onClick={() => signReminder(item.userId)}>
			Recordar
		</Button>
	);

	const onClickBlock = (item) => {
		setUserId(item.userId);
		setHandleTypeConfirm("block");
		setTextConfirm(
			`Se enviará un correo con la liga para firmar en bloque los documentos pendientes de firma que tenga el usuario.`
		);
		setOpenAlertConfirm(true);
	};

	const handleCloseAlert = () => {
		setOpenAlert(false);
		setMessage("");
		setTypeMessage("");
	};

	useEffect(() => {
		splitUsers();
	}, [signers]);

	useEffect(() => {
		const { authorities, userId, showBlockSignatureButton } = getCurrentUser();
		setAllowModify(
			authorities.includes("Seguimiento a actividades generadas por otros") ||
			(activity.username === userId &&
				authorities.includes("Crear Actividad de Firma"))
		);
		setShowBlockButton(showBlockSignatureButton);
	}, []);

	return (
		<>
			<TabContext value={selectedTab}>
				<DialogTitle className={classes.titleSigners}>Firmantes</DialogTitle>
				<TabList onChange={(evt, value) => setSelectedTab(value)}>
					<Tab label="Firmado" value="1" />
					<Tab label="Por Firmar" value="2" />
				</TabList>

				<TabPanel key={"tab-firmantes-1"} value="1" className={isRejected ? classes.signersRejected : classes.completedSigners}>
					<Grid container spacing={3}>
						{signed.length > 0 ? (
							signed.map((item) => (
								<Grid key={uuid()} item container spacing={3} alignItems="center" justifyContent={item.signed ? 'space-around' : 'center'}>

									<Grid item xs={1} className={classes.userLogo}>
										<div
											className="user-icon-lg"
											title={item.name}
											style={{ background: item.color }}>
											{item.name.charAt(0).toUpperCase()}
										</div>
									</Grid>

									<Grid item xs={item.signed ? 6 : 4}>
										<span title={item.name}>{formatTextSigned(item.name)}</span> <br />
										<span className="primary-text" title={item.email}>
											{formatTextSigned(item.email)}
										</span>
									</Grid>

									{item.rejected && (
										<Grid item container xs={5} alignItems="center">

											<Grid item container xs justifyContent="flex-end" alignItems="center" spacing={1}>
												<Grid item>
													<span className={classes.rejected}>RECHAZÓ</span>
												</Grid>
												{item.reason && <Grid item>
													<HtmlTooltip
														placement="top-start"
														title={
															<>
																<h3>MOTIVO DEL RECHAZO</h3>
																<p>{item.reason}</p>
															</>
														}
													>
														<InfoIcon color="action" fontSize="large" />
													</HtmlTooltip>
												</Grid>}
											</Grid>

											<Grid item container xs justifyContent="flex-end">
												{buttonReminder(item)}
											</Grid>

										</Grid>
									)}

									{
										(activity.hasVerificationId && item.signed) ? (
											<Grid item xs={3}>
												{
													item.hasVerificationId
														? <span className={classes.idVerified}>ID Verificado</span>
														: <span>Verificación de ID - No solicitado</span>
												}
											</Grid>
										) : <></>
									}

									{/* <Grid container xs className={classes.divider}>
										<div className="col-md-12">
											<hr />
										</div>
									</Grid> */}
								</Grid>
							))
						) : (
							<div className="no-signers-available">No hay firmantes</div>
						)}
					</Grid>
				</TabPanel>

				<TabPanel
					key={"tab-firmantes-2"}
					value="2"
					className={classes.pendingSigners}>
					{
						<Grid container spacing={3} style={{ width: canceled ? '450px' : 'auto' }}>
							{notSigned.length > 0 ? (
								notSigned.map((item, index) => (
									<Grid key={uuid()} container item spacing={3} alignItems="center">

										<Grid item xs={1} className={classes.userLogo}>
											<div
												className="user-icon-lg"
												title={item.name}
												style={{ background: item.color }}>
												{item.name.charAt(0).toUpperCase()}
											</div>
										</Grid>

										<Grid item xs={4}>
											<span title={item.name}>{formatText(item.name)}</span> <br />
											<span className="primary-text" title={item.email}>
												{formatText(item.email)}
											</span>
										</Grid>

										{!canceled && (
											<Grid item container xs={5} spacing={1}>
												<Grid item container spacing={2}>
													{(allowModify && !expired) && (
														<Grid item container xs sm={6}>
															<Button
																variant="contained"
																color="primary"
																onClick={() => handleModify(item)}>
																Modificar
															</Button>
														</Grid>
													)}

													<Grid
														item
														container
														xs
														sm={allowModify ? 6 : 12}
														justifyContent="flex-end">
														{
															hasOrder && (index === 0 && !isRejected)
																? buttonReminder(item)
																: !hasOrder
																	? buttonReminder(item)
																	: null
														}
													</Grid>
												</Grid>
												{item.inBlock > 1 && showBlockButton && (
													<Grid item container direction="column">
														<Button
															variant="contained"
															color="primary"
															onClick={() => onClickBlock(item)}>
															Correo - Firma en bloque
														</Button>
													</Grid>
												)}
											</Grid>
										)}

										{
											activity.hasVerificationId ? (
												<Grid item xs={2}>
													{
														item.hasVerificationId
															? <></>
															: <span>Verificación de ID - No solicitado</span>
													}
												</Grid>
											) : <></>
										}


										<Grid item container xs={12} className={classes.divider}>
											<div className="col-md-12">
												<hr />
											</div>
										</Grid>
									</Grid>
								))
							) : (
								<div className="col-12 center-text">
									No hay firmas restantes
								</div>
							)}
						</Grid>
					}
				</TabPanel>

				<Grid item container spacing={4} xs={12} direction="row-reverse" className={classes.footer}>
					<Grid item>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => onClose()}>
							Cerrar
						</Button>
					</Grid>
				</Grid>
			</TabContext>

			{openForm && dataForm && (
				<FormModifiedSigners
					{...dataForm}
					open={openForm}
					setOpen={handleClose}
					hasNom151={hasNom151}
					submitActions={handleSubmit}
				/>
			)}

			{openAlert && typeMessage && message && (
				<Alert
					open={openAlert}
					title={message}
					onConfirm={handleCloseAlert}
					type={typeMessage}
				/>
			)}

			{openAlertConfirm && (
				<AlertConfirm
					open={openAlertConfirm}
					onCancel={handleConfirmCancel}
					onConfirm={handleConfirm}
					textContent={textConfirm}
				/>
			)}

			<Backdrop className={classes.backdrop} open={openLoader}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</>
	);
};
