import React, { Component } from 'react';
import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormHelperText,
  FormControl,
  IconButton
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

class RenderTextField extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    }
  }

  render() {
    const {
      input,
      label,
      required,
      type,
      icon,
      passwordAction,
      onKeyUp,
      meta: {
        error,
        touched
      }
    } = this.props;
    const { showPassword } = this.state;

    return (
      <FormControl
        variant="outlined"
        color="primary"
        fullWidth={true}
        margin="dense"
        error={touched && (error && error.length > 0)}
      >
        <InputLabel>{`${label}${required ? '*' : ''}`}</InputLabel>

        <OutlinedInput
          {...input}
          type={passwordAction ? (showPassword ? 'text' : 'password') : type}
          onChange={event => input.onChange(event)}
          onKeyUp={() => onKeyUp && onKeyUp(input.value)}
          endAdornment={
            <React.Fragment>
              <InputAdornment position="end">
                {
                  passwordAction ?
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => this.setState({ showPassword: !showPassword })}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                    :
                    <React.Fragment>
                      {icon}
                    </React.Fragment>
                }
              </InputAdornment>
            </React.Fragment>
          }
          labelWidth={label.length * 8.5}
        />

        <FormHelperText>{(touched && error) && error}</FormHelperText>
      </FormControl>
    );
  }
}

export default RenderTextField;
