import { authHeader, vaultInstance } from "../../../configs/axios.config"



export const getVaultsUser = () => vaultInstance({
  headers: authHeader()
})

export const getVaultsUserDashboard = () => vaultInstance({
  url: '/dashboard',
  headers: authHeader()
})


export const getFileSystem = (vaultId, path) => vaultInstance({
  url: `/${vaultId}`,
  headers: authHeader(),
  params: {
    p: path
  }
})


export const getVaultById = (vaultId) => vaultInstance({
  url: `/${vaultId}/details`,
  headers: authHeader(),
})


export const getVaultUsers = (vaultId) => vaultInstance({
  url: `/${vaultId}/users`,
  headers: authHeader(),
})


export const getVaultsByUser = () => vaultInstance({
  url: `/byUser`,
  headers: authHeader(),
})


export const allowStoreVaults = () => vaultInstance({
  url: `/allowStoreVaults`,
  headers: authHeader(),
})


export const editVault = (vaultId, body) => vaultInstance({
  method: "PUT",
  url: `/${vaultId}/edit`,
  headers: authHeader(),
  data: body
})


export const assignUsersVault = (vaultId, body) => vaultInstance({
  method: "PUT",
  url: `/${vaultId}/assignUsers`,
  headers: authHeader(),
  data: body
})


export const enabledVault = (vaultId, body) => vaultInstance({
  method: "PUT",
  url: `/${vaultId}/enabled`,
  headers: authHeader(),
  data: body
})


export const createVault = (body) => vaultInstance({
  method: "POST",
  url: `/create`,
  headers: authHeader(),
  data: body
})


export const getElementsSearchedBar = (query) => vaultInstance({
  url: '/search',
  headers: authHeader(),
  params: query
})


