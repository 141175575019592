import React, { useEffect } from "react";
import { useState } from "react";
import {
  Dialog,
  Grid,
  Button,
  makeStyles,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import { TextFieldNative } from "../../fields/text-field-native";
import "./form-signature-activity.scss"
import { SelectedUsersSimbols } from "./SelectedUsersSimbols";
import { useDispatch, useSelector } from "react-redux";
import { addToGroupActions } from "../../../../../../core/actions/addToGroup.actions.js";
import { DocumentsHttp } from "../../../../../../core/http/documents.http";
import saveAs from "file-saver";
import { Alert } from "axeleratum-sgc-frontend-library";
import { authActions } from "../../../../../../core/actions";
import { PopupGroupSign } from "../../../popup/PopupGroupSign.jsx";
import { DocumentSignatures } from "./DocumentSignatures.jsx";
import { addDocumentToGroupSign, deletePreparation, editPreparation, getPreparationByDocumentId } from "../../../../../../core/http/functionRequests/signature.http.js";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
  },
  container: {
    padding: "15px",
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'inherit'
  },
  accionsContainer: {
    display: 'flex',
    alignItems: "flex-end",
    flexDirection: 'column',
    width: 'inherit',
    gap: "5px",
  }
}));

export const AddGroupSign = ({ open, onCancel, document, onSubmit, edit }) => {

  const documentsHttp = new DocumentsHttp();
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);
  const stickers = useSelector(state => state.addToGroupReducer.stickers);
  const signers = useSelector(state => state.addToGroupReducer.signers);

  const [name, setName] = useState(`Firmar - ${document.name}`);
  const [description, setDescription] = useState("");
  const [nameValidation, setNameValidation] = useState("");
  const [message, setMessage] = useState("");
  const [typeMessage, setTypeMessage] = useState("error");
  const [openUsers, setOpenUsers] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSignDialog, setOpenSignDialog] = useState(false);
  const [load, setLoad] = useState(false);
  const [filePdf, setFilePdf] = useState(null);
  const [titlePdf, setTitlePdf] = useState(null);
  const [preparedId, setPreparedId] = useState(null)

  const filterItem = (item) => stickers?.filter((e) => e.userId === item.userId).length !== 0;

  const finishProcess = () => {
    dispatch(addToGroupActions.restartGroup());
  }

  const handleCancel = () => {
    finishProcess();
    onCancel();
  }

  const handleSubmit = async () => {
    setLoad(true);
    if (!name) {
      setNameValidation("El nombre de la actividad es requerido");
      return
    }
    setNameValidation("");

    const reviewers = signers.map(user => ({
      email: user.email,
      userId: user.userId,
      name: user.name,
      color: user.color ? user.color : "darkgray",
      order: 0,
      type: user.type,
      rfc: user.rfc ?? "",
      temporal: !!user.temporal,
      stroke: user.stroke,
    }))

    const body = {
      activityName: name,
      description,
      stickers,
      signers: reviewers,
    }
    console.log(body);

    try {
      let message = ""
      if (!!preparedId) {
        await editPreparation(preparedId, body)
        message = "Preparación editada correctamente";
      } else {
        await addDocumentToGroupSign(document.id, body)
        message = "Documento preparado para grupo de firma";
      }

      setLoad(false);
      finishProcess();
      onSubmit(message);

    } catch (error) {
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
      setLoad(false);
      setMessage(error)
      setTypeMessage("error")
      setOpenAlert(true);
    }

  }

  const getPdf = async () => {
    setLoad(true);

    const documentId = document.id;
    const nameDocument = document.name;

    documentsHttp.downloadDocumentById(
      documentId,
      (resp, extension) => {
        if (extension.toLowerCase() === ".pdf") {
          const url = URL.createObjectURL(
            new Blob([resp.data], {
              type: "application/pdf",
            })
          );

          setFilePdf(url);
          setTitlePdf(nameDocument + extension)
          setLoad(false)

          setOpenSignDialog(true);
        } else {
          const blob = new Blob([resp.data], {
            type: "application/octet-stream",
          });
          saveAs(blob, `${document.name}${extension}`);
        }
      },
      (error) => {
        console.log("error", error);
        if (error.response.status === 401) {
          dispatch(authActions.userLoggedOut())
        }
        setOpenAlert(true);
        setTypeMessage("error");
        setMessage("Ocurrió un error al abrir documento. Intente más tarde.");
        setLoad(false);

      },
      true
    );
  }

  const requestPreparedDocument = async () => {
    try {
      const response = await getPreparationByDocumentId(document.id)
      console.log(response);
      setName(response.activityName)
      setDescription(response.description)
      dispatch(addToGroupActions.loadPreparation(response))
      setPreparedId(response.id)

    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
      setOpenAlert(true);
      setTypeMessage("error");
      setMessage("Ocurrió un error al abrir la preparacion del documento. Intente más tarde.");
    } finally {
      setLoad(false)
    }
  }

  const handleDeleteFromGroup = async () => {
    setLoad(true)
    try {
      await deletePreparation(preparedId);
      finishProcess();
      onSubmit("Documento eliminado de grupo de firma correctamente.");
    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
      setOpenAlert(true);
      setTypeMessage("error");
      setMessage("Ocurrió un error al eliminar la preparación del documento. Intente más tarde.");
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    if (edit) {
      setLoad(true)
      requestPreparedDocument()
    }
  }, [])


  return (<>
    <Dialog open={open} id="Modal-Create-Signature-Activity">
      <DialogContent
        className={classes.dialogContent}
        id="Create-Signature-DialogContent">
        <Grid
          container
          spacing={1}
          className={classes.container}
          id="Create-Signature-Container-Grid">
          <Grid item container xs={12} style={{ marginBottom: "15px" }} justifyContent="space-between">
            <Grid item>
              <h3 style={{ margin: "2px" }}>{"Preparar documento en Grupo"}</h3>
            </Grid>
            {
              (edit && !load && preparedId) ? (
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={handleDeleteFromGroup}
                    disabled={load}
                  >
                    Eliminar
                  </Button>
                </Grid>
              ) : <></>
            }
          </Grid>
          <Grid item >
            <span className="fa fa-file primary-text fa-2x doc" />
            <Tooltip title={document.name}>
              <span className="doc">
                {(document.name + document.extension).length > 40
                  ? document.name.slice(0, 37) + "..."
                  : document.name + document.extension}
              </span>
            </Tooltip>
          </Grid>

          <Grid item xs={12}>
            <TextFieldNative
              inputProps={{ multiline: true, maxRows: 6 }}
              label="Nombre de la actividad"
              value={name}
              message={nameValidation}
              onChange={(e) => setName(e)}
              required
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldNative
              label="Descripción (opcional)"
              value={description}
              message={""}
              onChange={(e) => setDescription(e)}
            />
          </Grid>

          <Grid item xs={12}>
            {load && (
              <div className="col-md-12 text-center">
                <strong className="mb-2">
                  Procesando su documento, espere por favor
                </strong>
                <LinearProgress
                  className="primary-color-bar"
                  color="primary"
                />
              </div>
            )}

            {!load && (
              <div className={classes.accionsContainer}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <SelectedUsersSimbols users={signers} />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenUsers(true)}
                  >
                    Firmantes
                  </Button>
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => getPdf()}
                  disabled={signers.length === 0}
                >
                  Posicionar Etiquetas
                </Button>
              </div>
            )}
          </Grid>

          <Grid item xs={12}>
            <div className={classes.buttonsContainer}>
              <Button
                className="font-color mr-2"
                variant="outlined"
                onClick={handleCancel}
                disabled={load}
              >
                Cancelar
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={handleSubmit}
                disabled={
                  load ||
                  !signers.every(filterItem) ||
                  stickers.length === 0 ||
                  name.trim().length === 0
                }
              >
                Aceptar
              </Button>
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>

    {openUsers && (
      <PopupGroupSign
        open={openUsers}
        onCancel={() => setOpenUsers(false)}
        onSubmit={() => setOpenUsers(false)}
        documentId={document.id}
      />
    )}

    {openSignDialog && (
      <DocumentSignatures
        open={openSignDialog}
        onClose={() => setOpenSignDialog(false)}
        signers={signers}
        url={filePdf}
        documentName={titlePdf}
        onSaveSigns={(stickers) => dispatch(addToGroupActions.saveStickers(stickers))}
        stickersValue={stickers}
      />
    )}

    {openAlert && (
      <Alert
        open={openAlert}
        title={message}
        onConfirm={() => {
          handleCancel();
        }}
        type={typeMessage}
      />
    )}

  </>)
}
