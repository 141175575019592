import { addToGroupConstants } from "../constants"

export const addToGroupActions = {
  saveUsersCanSign: (users) => ({
    type: addToGroupConstants.USERS_ABLE_TO_SIGN_GROUP,
    payload: users,
  }),

  saveSigners: (signers) => ({
    type: addToGroupConstants.SAVE_SIGNERS_GROUP,
    payload: signers,
  }),

  saveStickers: (stickers) => ({
    type: addToGroupConstants.SAVE_SIGNERS_STICKERS_GROUP,
    payload: stickers,
  }),

  editSigner: (data) => ({
    type: addToGroupConstants.EDIT_PREPARED_SIGNER_GROUP,
    payload: data
  }),

  loadPreparation: (data) => ({
    type: addToGroupConstants.LOAD_PREPARATION_GROUP,
    payload: data
  }),

  saveFinalSigners: () => ({ type: addToGroupConstants.SAVE_FINAL_SIGNERS_GROUP }),

  restartGroup: () => ({ type: addToGroupConstants.RESTART_GROUP }),

}