import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { v4 as uuidv4 } from 'uuid';
import { useState } from "react";
import { TextFieldNative } from "../../control/calendar/forms/fields/text-field-native";

export const FormTemporalUsersGroup = ({ submitActions, onCancel, open, hideRfc = false, preparedSigners, availableUsers }) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [rfc, setRfc] = useState("");
  const [errors, setErrors] = useState({ name: "", email: "", rfc: "" })

  const restartErrors = () => setErrors({ name: "", email: "", rfc: "" })
  const addError = (type, value) => setErrors(prev => ({ ...prev, [type]: value }))

  const handleSubmit = () => {
    let existErrors = false;
    if (!name && !errors.name) {
      existErrors = true
      setErrors(prev => ({ ...prev, name: 'El nombre del usuario es requerido' }))
    }

    if (name) {
      if (name > 50) {
        existErrors = true
        setErrors(prev => ({ ...prev, name: 'El nombre del usuario no puede ser mayor a 100 caracteres' }))
      }
    }

    if (!email && !errors.email) {
      existErrors = true
      setErrors(prev => ({ ...prev, email: 'El email es requerido' }))
    } else {
      const emailReg = new RegExp(/\S+@\S+\.\S+/);
      if (!emailReg.test(email)) {
        existErrors = true
        setErrors(prev => ({ ...prev, email: "Email inválido" }))
      }
    }

    if (rfc && !errors.rfc) {
      const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);

      if (!rfcReg.test(rfc)) {
        existErrors = true
        setErrors(prev => ({ ...prev, rfc: "RFC inválido" }))
      }
    }

    if (existErrors) return

    restartErrors()

    const id = uuidv4();
    submitActions({
      id,
      userId: id,
      name: name.trim(),
      email: email.trim().toLowerCase(),
      rfc: rfc.trim(),
      temporal: true,
    })
  }

  const validateName = () => {
    const query = el => el.name.trim().toLowerCase() === name.trim().toLowerCase();
    const existInSigners = preparedSigners.filter(el => !el.temporal).find(query);
    const existInTemporalSigners = preparedSigners.filter(el => el.temporal).find(query);
    const existInUsers = availableUsers.find(query);

    if (name === "") addError("name", "El nombre del usuario es requerido.")
    else if (!!existInSigners) addError("name", "Este nombre ya está registrado en un usuario catalogado en la plataforma y participa como firmante.")
    else if (!!existInUsers) addError("name", "Este nombre ya está registrado en un usuario catalogado en la plataforma.")
    else if (!!existInTemporalSigners) addError("name", "Este nombre ya está registrado en un usuario como firmante.")
    else addError("name", "")
  }

  const validateEmail = () => {
    const query = el => el.email.toLowerCase() === email.toLowerCase();
    const emailReg = new RegExp(/\S+@\S+\.\S+/);
    const existInSigners = preparedSigners.filter(el => !el.temporal).find(query);
    const existInTemporalSigners = preparedSigners.filter(el => el.temporal).find(query);
    const existInUsers = availableUsers.find(query);

    if (email === "") addError("email", "El correo es requerido.")
    else if (!emailReg.test(email)) addError("email", "Correo inválido")
    else if (!!existInSigners) addError("email", "Este correo ya está registrado en un usuario catalogado en la plataforma y participa como firmante.")
    else if (!!existInUsers) addError("email", "Este correo ya está registrado en un usuario catalogado en la plataforma.")
    else if (!!existInTemporalSigners) addError("email", "Este correo ya está registrado en un usuario como firmante.")
    else addError("email", "")
  }

  const validateRFC = () => {
    const query = el => el.rfc === rfc;
    const rfcReg = new RegExp(/^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{0,3})$/i);
    const existInSigners = preparedSigners.filter(el => !el.temporal).find(query);
    const existInTemporalSigners = preparedSigners.filter(el => el.temporal).find(query);
    const existInUsers = availableUsers.find(query);

    if (rfc === "") addError("rfc", "")
    else if (!rfcReg.test(email)) addError("rfc", "RFC invalido")
    else if (!!existInSigners) addError("rfc", "Este RFC ya está registrado en un usuario catalogado en la plataforma y participa como firmante.")
    else if (!!existInUsers) addError("rfc", "Este RFC ya está registrado en un usuario catalogado en la plataforma.")
    else if (!!existInTemporalSigners) addError("rfc", "Este RFC ya está registrado en un usuario como firmante.")
    else addError("rfc", "")
  }

  return (
    <Dialog open={open}>
      <DialogTitle>Usuario Externo</DialogTitle>
      <DialogContent style={{ paddingTop: "0px", marginTop: "-16px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextFieldNative
              label="Nombre*"
              value={name}
              message={errors.name}
              onChange={(e) => setName(e)}
              onBlur={validateName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldNative
              label="Email*"
              value={email}
              type="email"
              message={errors.email}
              onChange={(e) => setEmail(e)}
              onBlur={validateEmail}
            />
          </Grid>
          {
            !hideRfc && (
              <Grid item xs={12}>
                <TextFieldNative
                  label="RFC (opcional)"
                  value={rfc}
                  message={errors.rfc}
                  onChange={(e) => setRfc(e)}
                  onBlur={validateRFC}
                />
              </Grid>
            )
          }
        </Grid>
      </DialogContent>
      <DialogActions style={{ justifyContent: "flex-end" }}>
        <Button
          className="font-color mr-2"
          variant="outlined"
          onClick={() => {
            restartErrors()
            onCancel();
          }}
          disabled={false}
        >
          Cancelar
        </Button>

        <Button
          color="primary"
          variant="contained"
          onClick={handleSubmit}
          disabled={!!Object.values(errors).find(el => el)}
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
