
import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@material-ui/core";
import { CardUser } from "./CardUser";
import "./popup-user.scss";
import { DraggableList } from "../../draggableList/DraggableList";
import { SortableItem } from "../../draggableList/SortableItem";

export const PopUpOrderSigners = ({ open, selected, onCancel, onSubmit }) => {

  const [usersSelected, setUsersSelected] = useState(selected);

  const handleSubmit = () => {
    onSubmit(usersSelected);
  }

  const handleCancel = () => {
    onCancel();
  }

  return (
    <Dialog className="popup-user" open={open}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: "10px" }}>
        <DialogTitle>Firmantes seleccionados</DialogTitle>
      </div>

      <DialogContent className="card-zone">
        <Grid
          container
          spacing={1}
          direction="column"
        >
          <DraggableList
            items={usersSelected}
            setItems={setUsersSelected}
          >
            {usersSelected.map((item, index) => (
              <SortableItem
                key={item.id}
                id={item.id}
              >
                <CardUser
                  index={index + 1}
                  name={item.name}
                  email={item.email}
                />
              </SortableItem>
            ))}
          </DraggableList>
        </Grid>

        {usersSelected.length === 0 && (
          <div className="text-center mt-2">
            No hay usuarios seleccionados
          </div>
        )}
      </DialogContent>

      <div>
        <DialogActions>

          <Button
            className="primary-text"
            color="primary"
            variant="outlined"
            onClick={() => handleCancel()}
          >
            Cancelar
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSubmit()}
          >
            Aceptar
          </Button>

        </DialogActions>
      </div>
    </Dialog>
  )
}
