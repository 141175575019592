
import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { UserHttp } from "../../../core/http/user.http";
import { tokenExpired } from "../../../core/utils";
import { authActions } from "../../../core/actions";
import "./popup-user.scss";
import { UserSelector } from "./UserSelector";
import { CardUser } from "./CardUser";

export const PopUpReleasers = ({ open, vaultId, selected, releasedBy = [], onCancel, onSubmit, pending = true, canceled = false }) => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [usersSelected, setUsersSelected] = useState(selected ?? []);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [openSelectUsers, setOpenSelectUsers] = useState(false);

  const handleSubmit = () => {
    onSubmit(usersSelected);
  }

  const handleCancel = () => {
    onCancel();
  }

  const searchUsersByVault = async () => {
    setLoad(true);
    try {
      const userhttp = new UserHttp();
      const search = await userhttp.getActiveUsersByVault(vaultId)
      const filterUsers = search.filter(el => !el.externalProvider && el.email !== "soporte@kasiasolutions.com")
      setAvailableUsers(filterUsers.filter(el => el.permissions.includes('Liberar documentos')));
    } catch (error) {
      console.error(error);
      setError(true);
    } finally {
      setLoad(false);
    }
  }

  useEffect(() => {

    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }
    searchUsersByVault()
  }, []);

  return (
    <>
      <Dialog className="popup-user" open={open}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: "10px" }}>
          <DialogTitle>Quien Libera</DialogTitle>
          {

            (!load && (selected.length != releasedBy.length || pending) && !canceled) && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenSelectUsers(true)}
              >
                Agregar
              </Button>
            )
          }
        </div>

        {error && (
          <DialogContent>
            <Alert severity="error">
              Hubo un problema al cargar lo usuarios inténtalo mas tarde
            </Alert>
          </DialogContent>
        )}

        {load ? (
          <DialogContent className="text-center">
            <CircularProgress />
          </DialogContent>
        ) : (
          <>
            {!error && (
              <>
                <DialogContent className="card-zone">
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                  >
                    {usersSelected.map((item) => (
                      <CardUser
                        key={item.id}
                        name={item.name}
                        email={item.email}
                        released={!!releasedBy && !!releasedBy.find(el => el === item.id)}
                        onDelete={canceled ? null : () => setUsersSelected(prev => prev.filter(el => el.id !== item.id))}
                      />
                    ))}
                  </Grid>

                  {usersSelected.length === 0 && (
                    <div className="text-center mt-2">
                      No hay usuarios seleccionados
                    </div>
                  )}
                </DialogContent>
              </>
            )}
          </>
        )}
        <div>
          <DialogActions>

            <Button
              className="primary-text"
              color="primary"
              variant="outlined"
              onClick={() => handleCancel()}
            >
              {
                (releasedBy.length === selected.length && !pending) || canceled
                  ? "Cerrar"
                  : "Cancelar"
              }
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
              disabled={(releasedBy.length === selected.length && !pending) || canceled}
            >
              Aceptar
            </Button>

          </DialogActions>
        </div>
      </Dialog>

      {
        openSelectUsers ? (
          <UserSelector
            open={openSelectUsers}
            users={availableUsers}
            selected={usersSelected}
            onSelect={setUsersSelected}
            onClose={() => setOpenSelectUsers(false)}
          />
        ) : <></>
      }
    </>
  )
}
