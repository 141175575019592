import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom';
import { Grid, Hidden } from "@material-ui/core";
import { authActions, alertActions } from "../../core/actions";
import CreateAccount from "./create-account";
import RestorePassword from "./restore-password";
import SignIn from "./sign-in";
import logoKasiaObscuro from "../../assets/img/logoKasiaHorizontalFondoObscuro.png";
import logoKasiaBlanco from "../../assets/img/logoKasiaHorizontalFondoBlanco.png";
import backgroundImage from "../../assets/img/background.png";
import { AuthService } from "../../core/services";
import { authConstants } from '../../core/constants';
import "./onboarding.css";
import { Alert } from '../from-library/alerts/Alert';

const SIGN_IN = "sign-in";
const CREATE_ACCOUNT = "create-account";
const RESTORE_PASSWORD = "restore-password";

const OnBoarding = (props) => {

  const params = useParams();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const loggedIn = useSelector(state => state.authReducer.loggedIn)
  const alert = useSelector(state => state.alertReducer)

  const [authority, setAuthority] = useState("");
  const [selectedForm, setSelectedForm] = useState(props.createAccount ? CREATE_ACCOUNT : SIGN_IN);
  const [alertSession, setAlertSession] = useState({ message: '', open: false, type: "info" })
  const [sessionData, setSessionData] = useState({ email: '', password: '', message: null })

  const openAlertSession = (message, type) => setAlertSession({ message, type, open: true })
  const closeAlertSession = () => setAlertSession({ message: "", type: "", open: false })

  const handleCloseAlertSession = async () => {
    try {
      const { clientKey } = params;
      const authService = new AuthService()
      closeAlertSession()
      const { data } = await authService.login(sessionData.email, sessionData.password, clientKey);
      dispatch(authActions.login(data))
      window.location.reload();
    } catch (error) {
      console.error(error);
      dispatch({ type: authConstants.LOGIN_FAILURE })
      if (error.response) {
        dispatch(alertActions.error(error.response.data.error))
        return
      }
      if (error.message) {
        dispatch(alertActions.error(error.message))
        return
      }

      dispatch(alertActions.error("Error en el servidor"))
    }
  }
  const handleCreateAccount = (form) => {
    const { userId, clientKey } = params;
    const id = userId

    sessionStorage.setItem("clientKey", clientKey)

    const newUser = {
      id,
      username: form.email.trim().toLowerCase(),
      completeName: form.completeName.trim(),
      email: form.email.trim().toLowerCase(),
      ssoAuth: false,
    };

    if (authority === "sso") {
      newUser.ssoAuth = true
    } else {
      newUser.password = form.password.trim()
    }

    dispatch(authActions.signUp(newUser))
  }

  const handleLogin = async (form) => {
    try {
      const authService = new AuthService()
      const { clientKey } = params
      const { data } = await authService.validateCredentials(form.email.trim().toLowerCase(), form.password, clientKey);

      if (data.message) {
        setSessionData({ email: form.email.trim().toLowerCase(), password: form.password, message: data.message })
        return
      }

      const { data: dataSession } = await authService.login(form.email.trim().toLowerCase(), form.password, clientKey);

      dispatch(authActions.login(dataSession))
      window.location.reload();
    } catch (error) {
      console.error(error);
      dispatch({ type: authConstants.LOGIN_FAILURE })
      if (error.response) {
        dispatch(alertActions.error(error.response.data.error))
        return
      }
      if (error.message) {
        dispatch(alertActions.error(error.message))
        return
      }

      dispatch(alertActions.error("Error en el servidor"))
    }
  }

  const renderForm = () => {
    if (selectedForm === SIGN_IN && !loggedIn) {
      return (
        <SignIn
          submitActions={handleLogin}
          onCreateAccount={() => setSelectedForm(CREATE_ACCOUNT)}
          onRecoverPassword={() => setSelectedForm(RESTORE_PASSWORD)}
          alert={alert}
          handleSession={(ev) => dispatch(authActions.activeSession(ev.target.checked))}
          userLoggedIn={() => dispatch(authActions.setUserLoggedIn())}
        />
      );
    }

    if (selectedForm === CREATE_ACCOUNT) {
      return (
        <CreateAccount
          submitActions={handleCreateAccount}
          onCancel={() => setSelectedForm(SIGN_IN)}
          errors={alert}
          sso={authority === 'sso'}
        />
      );
    }

    if (selectedForm === RESTORE_PASSWORD) {
      return (
        <RestorePassword
          onReturnSingIn={() => setSelectedForm(SIGN_IN)}
          submitActions={(form) => dispatch(authActions.sendEmailPassword(form, params.clientKey))}
          alert={alert}
        />
      );
    }
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location?.search);
    if (loggedIn) {
      history.push({ pathname: "/dashboard" });
    } else {
      const auth = searchParams.get('authority')
      setAuthority(auth)
    }
  }, []);

  useEffect(() => {
    if (sessionData.message) {
      openAlertSession(sessionData.message, "info")
    }
  }, [sessionData])


  useEffect(() => {
    if (loggedIn) {
      history.push({ pathname: "/dashboard" });
      dispatch(alertActions.close())
    }

    if (alert?.message) {
      dispatch(alertActions.close())
    }

    if (selectedForm === CREATE_ACCOUNT && alert?.type === "success") {
      setSelectedForm(SIGN_IN)
      history.push({ pathname: "/" + sessionStorage.getItem("clientKey") })
    }
  }, [selectedForm, loggedIn]);

  return (
    <Grid container>
      <Grid
        item
        container
        md={6}
        xs={12}
        sm={12}
        className="h-100"
        direction="column"
        alignItems="center"
        justifyContent="center"
        p-b={6}
      >
        <Grid item md={8} sm={7}>
          {props.children ? (
            props.children
          ) : (
            <>
              <Hidden mdUp>
                <img
                  style={{
                    width: "25%",
                    minWidth: "200px",
                    display: "block",
                    margin: "auto",
                  }}
                  alt="Käsiä Solutions"
                  src={logoKasiaBlanco}
                />
              </Hidden>
              {renderForm()}
            </>
          )}
        </Grid>
      </Grid>

      <div className="xs-not-show">
        <Grid
          item
          container
          className="onboarding-right-panel"
          md={6}
          justifyContent="center"
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <Grid
            container
            item
            className="animate__animated animate__backInUp"
            md={12}
            justifyContent="center"
            style={{ zIndex: "100" }}
          >
            <img
              className="right-panel-logo"
              alt="Käsiä Solutions"
              src={logoKasiaObscuro}
            />
          </Grid>
        </Grid>
      </div>


      {
        alertSession.open && (
          <Alert
            open={alertSession.open}
            title={alertSession.message}
            onConfirm={handleCloseAlertSession}
            type={alertSession.type}
          />
        )
      }
    </Grid>
  )
}

export default OnBoarding