import React from 'react';
import { List, ListItem, Grid, Typography, Tooltip, Avatar, Divider } from '@material-ui/core';
import formatDate from '../../utils/formatDate';

const DetailsActivity = props => {
  const { classes, dataElement } = props;

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <List className={`${classes.list} ${classes.detailsContainer}`}>
          {dataElement && dataElement.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <ListItem className={classes.listItem}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography color="primary" variant={'caption'}>{formatDate(item.date)}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={4} className={classes.avatarContainer}>
                          <Tooltip title={item.user}>
                            <Avatar
                              className={classes.avatar}
                            >
                              {item.user && item.user.toUpperCase().charAt(0)}
                            </Avatar>
                          </Tooltip>
                        </Grid>
                        <Grid item xs={8}>
                          <Typography color="primary">
                            {item.user} {item.action}.
                            {
                              // {item.user} {item.action}{(item.isFolder ? ` folder.` :
                              //   item.action.includes("escaneada") ? `.` : ` documento.`)}
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                  </Grid>
                </ListItem>
                <Divider classes={{ root: classes.divider }} component="li" />
              </React.Fragment>
            )
          })}

          {!dataElement && (
            <Grid item xs={12} >
              No hay actividades recientes.
            </Grid>
          )}

        </List>
      </Grid>
    </React.Fragment>
  )
}

export default DetailsActivity