import React, { useEffect } from "react";
import { useState } from "react";
import {
  Dialog,
  Grid,
  Button,
  makeStyles,
  DialogContent,
  LinearProgress,
  Tooltip,
} from "@material-ui/core";
import moment from "moment";
import { TextFieldNative } from "../../fields/text-field-native";
import "./form-signature-activity.scss"
import { SelectedUsersSimbols } from "./SelectedUsersSimbols";
import { PopUpExternalUser } from "../../../popup/PopUpExternalUser";
import { useDispatch, useSelector } from "react-redux";
import { activityActions } from "../../../../../../core/actions/activity.actions";
import { DocumentsHttp } from "../../../../../../core/http/documents.http";
import saveAs from "file-saver";
import { Alert } from "axeleratum-sgc-frontend-library";
import { SignatureNotificationConfig } from "./SignatureNotificationConfig";
import { getCurrentUser } from "../../../../../../core/helpers";
import { authActions } from "../../../../../../core/actions";
import { PopUpEndorse } from "../../../popup/PopUpEndorse";
import { DocumentEndorsement } from "./DocumentEndorsement.jsx";
import { createEndorseActivity, getAllStickers } from "../../../../../../core/http/functionRequests/signature.http.js";
import { AlertConfirm } from "../../../../../from-library/alerts/AlertConfirm.jsx";

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: '0px',
    overflow: 'hidden',
  },
  container: {
    padding: "15px",
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: 'inherit'
  },
  accionsContainer: {
    display: 'flex',
    alignItems: "flex-end",
    flexDirection: 'column',
    width: 'inherit',
    gap: "5px",
  }
}));

export const Endorse = ({ open, onCancel, document, onSubmit, hasSet = false }) => {

  const documentsHttp = new DocumentsHttp();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameValidation, setNameValidation] = useState("");
  const [message, setMessage] = useState("");
  const [typeMessage, setTypeMessage] = useState("error");
  const [openUsers, setOpenUsers] = useState(false);
  const [openExternal, setOpenExternal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openSignNotification, setOpenSignNotification] = useState(false);
  const [file, setFile] = useState(document);
  const [openSignDialog, setOpenSignDialog] = useState(false);
  const [load, setLoad] = useState(false);
  const [filePdf, setFilePdf] = useState(null);
  const [stickersValue, setStickersValue] = useState(null);
  const [titlePdf, setTitlePdf] = useState(null);
  const [usersToSign, setUsersToSign] = useState([]);
  const [occupied, setOccupied] = useState([])
  const [alertConfirm, setAlertConfirm] = useState({ title: "", message: "", open: false })
  const [strokeAndText, setStrokeAndText] = useState(false)
  const [textWithSignature, setTextWithSignature] = useState(false)
  const [onlyTextSignature, setOnlyTextSignature] = useState(true)

  const filterItem = (item) => stickersValue?.filter((e) => e.userId === item.userId).length !== 0;

  const finishProcess = () => {
    dispatch(activityActions.restartProcess());
  }

  const handleCancel = () => {
    finishProcess();
    onCancel();
  }

  const addExternalsToFile = (users) => {
    file.externals = users;
    setFile(file);
    setOpenExternal(false);
  }

  const handleSaveNotifications = (body) => {
    file.notifications = body;
    setFile(file);
    setOpenSignNotification(false);
  }

  const handleSubmit = () => {
    setLoad(true);
    setAlertConfirm({ title: "", message: "", open: false })
    if (!name) {
      setNameValidation("El texto del endoso es requerido");
      return
    }
    setNameValidation("");
    const { monthsExpirationDefault, endorsmentWithSignature } = getCurrentUser()
    const threeMonths = moment().add(monthsExpirationDefault ?? 3, 'months').format("YYYY-MM-DD");

    let userStroke = endorsmentWithSignature ? `stroke+endorsment` : "endorsment";

    if (hasSet) {
      userStroke = textWithSignature ? `stroke+endorsment` : "endorsment"
    }

    const reviewers = file.reviewers.map((user, index) => ({
      email: user.email,
      userId: user.userId,
      name: user.name,
      color: user.color ? user.color : "darkgray",
      order: file.withOrder ? index : 0,
      type: user.type,
      rfc: user.rfc ?? "",
      temporal: !!user.temporal,
      stroke: user.endorser
        ? userStroke
        : user.stroke,
      endorser: user.endorser ?? false,
      hasVerificationId: user.hasVerificationId
    }))

    const body = {
      name,
      description,
      date: moment(new Date()).format("YYYY-MM-DD"),
      hour: moment(new Date()).format("HH:MM"),
      withOrder: file.withOrder && reviewers.length > 1,
      notifyExpiringDay: file?.notifications?.notifyExpiringDay ?? true,
      block_expired: file?.notifications?.block_expired ?? true,
      subject: file?.notifications?.subject,
      message: file?.notifications?.message,
      remind_every: file?.notifications?.remind_every,
      expiration_date: file?.notifications?.expiration_date ? file.notifications.expiration_date : threeMonths,
      offset: new Date().getTimezoneOffset(),
      externals: file.externals.map(user => ({ email: user.email, name: user.name })),
      stickers: stickersValue,
      reviewers,
    }

    createEndorseActivity(file.file.documentId, body, hasSet ? "textsigner" : "")
      .then(data => {
        setLoad(false);
        setOpenAlert(false);
        setMessage("Actividad creada");
        setTypeMessage("success");
        finishProcess();
        onSubmit();
      }).catch(error => {
        if (error.response.status === 401) {
          dispatch(authActions.userLoggedOut())
        }
        const msg = error.response.data.error
        setLoad(false);
        setMessage(msg ?? "Error en el servidor")
        setTypeMessage("error")
        setOpenAlert(true);
      })
  }

  const addUsersToFile = (form, withOrder) => {

    setUsersToSign(form)
    if (stickersValue?.length > 0) {
      setStickersValue(old => old.reduce((acc, curr) => {
        const existUser = form.find(el => el.userId === curr.userId);
        if (existUser) {
          return [...acc, curr]
        }
        return acc;
      }, []))
    }

    file.withOrder = withOrder;
    file.reviewers = form;
    file.stickersValue = stickersValue;

    setFile(file);
    setOpenUsers(false);
  }

  const getPdf = async () => {
    setLoad(true);

    const documentId = file.file.documentId;
    const nameDocument = file.file.name;

    try {
      const request = await getAllStickers(documentId)

      setOccupied(request)

      documentsHttp.downloadDocumentById(
        documentId,
        (resp, extension) => {
          if (extension.toLowerCase() === ".pdf") {
            const url = URL.createObjectURL(
              new Blob([resp.data], {
                type: "application/pdf",
              })
            );

            setFilePdf(url);
            setTitlePdf(nameDocument + extension)
            setLoad(false)

            setOpenSignDialog(true);
          } else {
            const blob = new Blob([resp.data], {
              type: "application/octet-stream",
            });
            saveAs(blob, `${document.name}${extension}`);
          }
        },
        (error) => {
          console.log("error", error);
          if (error.response.status === 401) {
            dispatch(authActions.userLoggedOut())
          }
          setOpenAlert(true);
          setTypeMessage("error");
          setMessage("Ocurrió un error al abrir documento. Intente más tarde.");
          setLoad(false);

        },
        true
      );

    } catch (error) {
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
    }
  }

  const handleAlertConfirm = () => {
    setAlertConfirm({
      open: true,
      title: hasSet ? "Texto" : "Texto del Endoso",
      message: <>Confirmar que el texto es correcto.<br /> Una vez enviado no se puede cancelar el proceso de firma.</>
    })
  }

  useEffect(() => {
    if (!hasSet) {
      const { endorsmentWithSignature } = getCurrentUser();
      setStrokeAndText(endorsmentWithSignature)
    }
  }, [])

  return (
    <>
      <Dialog open={open} id="Modal-Create-Signature-Activity">
        <DialogContent
          className={classes.dialogContent}
          id="Create-Signature-DialogContent">
          <Grid
            container
            spacing={1}
            className={classes.container}
            id="Create-Signature-Container-Grid"
          >
            <Grid item container>
              <Grid item container xs={hasSet ? 10 : 12}>
                <Grid item xs={12} style={{ marginBottom: "15px" }}>
                  <h3 style={{ margin: "2px" }}>{hasSet ? "Añadir texto y/o firmante" : "Nuevo endoso"}</h3>
                </Grid>
                <Grid item >
                  <span className="fa fa-file primary-text fa-2x doc" />
                  <Tooltip title={document.name + document.extension}>
                    <span className="doc">
                      {document.name.length > 40
                        ? document.name.slice(0, 37) + "..."
                        : document.name + document.extension}
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
              {
                hasSet && (
                  <Grid item container xs direction="column">
                    <Grid item>
                      <input
                        type="radio"
                        checked={onlyTextSignature}
                        name="Texto"
                        onChange={() => {
                          setOnlyTextSignature(prev => {
                            setTextWithSignature(prev)
                            return !prev
                          })
                        }}
                      />
                      {"Texto"}
                    </Grid>

                    <Grid item>
                      <input
                        type="radio"
                        checked={textWithSignature}
                        name="Texto"
                        onChange={() => {
                          setTextWithSignature(prev => {
                            setOnlyTextSignature(prev)
                            return !prev
                          })
                        }}
                      />
                      {"Firmante"}
                    </Grid>
                  </Grid>
                )
              }
            </Grid>

            <Grid item xs={12}>
              <TextFieldNative
                inputProps={{ multiline: true, maxRows: 6 }}
                label="Texto"
                value={name}
                message={nameValidation}
                onChange={(e) => setName(e)}
                required
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldNative
                label="Descripción (opcional)"
                value={description}
                message={""}
                onChange={(e) => setDescription(e)}
              />
            </Grid>

            <Grid item xs={12}>
              {load && (
                <div className="col-md-12 text-center">
                  <strong className="mb-2">
                    Procesando su documento, espere por favor
                  </strong>
                  <LinearProgress
                    className="primary-color-bar"
                    color="primary"
                  />
                </div>
              )}

              {!load && (
                <div className={classes.accionsContainer}>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <SelectedUsersSimbols users={file.reviewers} />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenUsers(true)}
                    >
                      {hasSet ? "Firmante" : "Firmantes"}
                    </Button>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => getPdf()}
                    disabled={file.reviewers.length === 0}
                  >
                    Posicionar Etiquetas
                  </Button>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <SelectedUsersSimbols />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenExternal(true)}
                      disabled={file.reviewers.length === 0}
                    >
                      Destinatarios No Firmantes (opcional)
                    </Button>
                  </div>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpenSignNotification(true)}
                    disabled={file.reviewers.length === 0}
                  >
                    Notificación y recordatorios (opcional)
                  </Button>
                </div>
              )}
            </Grid>

            <Grid item xs={12}>
              <div className={classes.buttonsContainer}>
                <Button
                  className="font-color mr-2"
                  variant="outlined"
                  onClick={handleCancel}
                  disabled={load}
                >
                  Cancelar
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleAlertConfirm}
                  disabled={
                    load ||
                    !usersToSign.every(filterItem) ||
                    !stickersValue ||
                    name.trim().length === 0
                  }
                >
                  Aceptar
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      {openUsers && (
        <PopUpEndorse
          open={openUsers}
          onCancel={() => setOpenUsers(false)}
          onSubmit={(users, withOrder) => addUsersToFile(users, withOrder)}
          checkedOrder={file.withOrder}
          documentId={document.file.documentId}
          hasSet={hasSet}
        />
      )}

      {openExternal && (
        <PopUpExternalUser
          open={openExternal}
          onSubmit={(users) => addExternalsToFile(users)}
          onCancel={() => setOpenExternal(false)}
          documentId={document.file.documentId}
        />
      )}

      {openSignNotification && (
        <SignatureNotificationConfig
          open={openSignNotification}
          onSave={(data) => handleSaveNotifications(data)}
          onCancel={() => setOpenSignNotification(false)}
          prev={file.notifications ?? {}}
          documentId={document.file.documentId}
          type={"endorse"}
        />
      )}

      {openSignDialog && (
        <DocumentEndorsement
          open={openSignDialog}
          onClose={() => setOpenSignDialog(false)}
          signers={file.reviewers}
          url={filePdf}
          documentName={titlePdf}
          onSaveSigns={(stickers) => setStickersValue(stickers)}
          stickersValue={stickersValue}
          currentSignatures={occupied}
          strokeAndText={strokeAndText || textWithSignature}
        />
      )}

      {openAlert && (
        <Alert
          open={openAlert}
          title={message}
          onConfirm={() => {
            handleCancel();
          }}
          type={typeMessage}
        />
      )}

      {
        alertConfirm.open ? (
          <AlertConfirm
            open={alertConfirm.open}
            title={alertConfirm.title}
            textContent={name.split('\n').map(el => <>{el}<br /></>)}
            onCancel={() => setAlertConfirm({ title: "", message: "", open: false })}
            onConfirm={handleSubmit}
            actionText={alertConfirm.message}
          />
        ) : <></>
      }
    </>
  );
}

