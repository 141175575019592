import { authHeader, axiosCalendarRequest, axiosSignatureRequest, axiosRequestBlockChain } from "../../configs/axios.config";
import moment from 'moment';
import { DocumentsHttp } from "./documents.http";
import { decodeToken } from "../utils";

export class CalendarHttp {

  documentsHttp = new DocumentsHttp();

  getMarks(success, error) {
    axiosCalendarRequest.get('/calendars/marks', { headers: authHeader() }).then(({ data }) => {
      success(data);
    }).catch((errorResp) => {
      error(errorResp);
    })
  }


  async getMonthActivities(monthYear, success, error) {
    try {
      const { data } = await axiosCalendarRequest.get(
        `/calendars/by-year-month/${monthYear}`,
        { headers: authHeader() }
      );
      let activities = [];
      // se implementa en endpoint calendars/by-year-month, se comenta hasta validar que funcione correctamente
      // for (const activity of data) {
      //   let erasable = false;
      //   let statusBpm = '';
      //   let rejected = false;

      //   for (const document of activity.documents) {
      //     if (document.statusBpm === 'Rechazo de Firma') {
      //       statusBpm = 'Rechazo de Firma';
      //     }

      //     for (const signer of document.signerReviewer) {
      //       if (activity.type === 'revision') {
      //         try {//PARCHE del estatus cuando no se a tocado la actividad de revision
      //           
      //           const review = (await axiosCalendarRequest.get(`/signer-reviewers/validate-review-user/${document.documentId}/${signer.userId}`, { headers: authHeader() })).data;

      //           if (review) {
      //             erasable = true;
      //           }
      //         } catch (e) { }
      //       } else {
      //         if (signer.signed) {
      //           erasable = true;
      //         }

      //         if (signer.rejected) {
      //           rejected = true;
      //         }
      //       }
      //     }
      //   }

      //   activities.push(
      //     {
      //       ...activity,
      //       erasable,
      //       statusBpm,
      //       rejected
      //     }
      //   )
      // }

      success(data);
    } catch (errorResp) {
      error(errorResp);
    }
  }


  async getActivities(date, success, error) {
    try {
      const data = (await axiosCalendarRequest.get(
        `/calendars/by-date/${moment(date).format('YYYY-MM-DD')}`,
        { headers: authHeader() }
      )).data;
      let activities = [];
      let statusBpm = '';
      let rejected = false;

      for (const activity of data) {
        let erasable = false;

        for (const document of activity.documents) {
          if (document.statusBpm === 'Rechazo de Firma') {
            statusBpm = 'Rechazo de Firma';
          }

          for (const signer of document.signerReviewer) {
            if (activity.type === 'revision') {
              try { //PARCHE del estatus cuando no se a tocado la actividad de revision
                // evaluar request
                const review = (await axiosCalendarRequest.get(`/signer-reviewers/validate-review-user/${document.documentId}/${signer.userId}`, { headers: authHeader() })).data;

                if (review) {
                  erasable = true;
                }
              } catch (e) { }
            } else {
              if (signer.signed) {
                erasable = true;
              }

              if (signer.rejected) {
                rejected = true;
              }
            }
          }
        }

        activities.push(
          {
            ...activity,
            erasable,
            statusBpm,
            rejected
          }
        )
      }

      success(activities);
    } catch (errorResp) {
      error(errorResp);
    }
  }

  createActivity(formData) {

    formData.token = sessionStorage.getItem('access_token');
    return axiosCalendarRequest.post('/calendars', formData, { headers: authHeader() });
  }

  updateActivity(formData) {
    formData.token = sessionStorage.getItem('access_token');
    return axiosCalendarRequest.put(
      `/calendars/update-activity/${formData.id}`,
      formData,
      { headers: authHeader() }
    );
  }

  deleteActivity(activityId) {
    return axiosCalendarRequest.put(
      `/calendars/delete-activity/${activityId}`,
      {},
      { headers: authHeader() }
    );
  }

  getFirm(documentId) {
    return axiosSignatureRequest.get(`/signature?documentId=${documentId}`, { headers: authHeader() });
  }

  getTurn(documentId) {
    return axiosCalendarRequest.get(`/signer-reviewers/get-turn/${documentId}`, { headers: authHeader() });
  }

  getPendingActivities() {
    return axiosCalendarRequest.get(`/signer-reviewers/get-pending-activities/`, { headers: authHeader() });
  }


  async getPendingDocuments(success, error) {
    const out = [];
    // corregir este procesamientro
    try {
      const { data } = await axiosCalendarRequest.get(`/signer-reviewers/get-pending-activities/`, { headers: authHeader() });

      for (let i = 0; i < data.length; i++) {
        const activity = data[i];
        const documents = activity.documents;
        let show = false;

        for (let j = 0; j < documents.length; j++) {
          if (activity.type === 'revision') {
            show = !(await axiosCalendarRequest.get(`/signer-reviewers/all-approvals/${documents[j].documentId}`, { headers: authHeader() })).data;
          } else {
            show = !(await axiosCalendarRequest.get(`/signer-reviewers/all-signatures/${documents[j].documentId}`, { headers: authHeader() })).data;
          }

          const firm = [];
          const notFirm = [];
          const signerReviewers = documents[j].signerReviewer;

          for (let k = 0; k < signerReviewers.length; k++) {
            const signer = signerReviewers[k];

            if (activity.type === 'revision') {
              // evaluar request
              const review = (await axiosCalendarRequest.get(`/signer-reviewers/validate-review-user/${documents[j].documentId}/${signer.userId}`, { headers: authHeader() })).data;

              if (review) {
                firm.push(signer);
              } else {
                notFirm.push(signer);
              }
            } else {
              if (signer.signed) {
                firm.push(signer);
              } else {
                notFirm.push(signer);
              }
            }
          }

          if (show) {
            out.push({
              ...documents[j],
              firm,
              notFirm,
              percentage: (firm.length / documents[j].signerReviewer.length) * 100
            });
          }
        }
      }

      success(out);
    } catch (e) {
      console.log(e);
      error();
    }

    return out;
  }

  getInvitations() {
    return axiosCalendarRequest.get(`/signer-reviewers/get-pending-documents`, { headers: authHeader() });
  }

  getInvitationsById(businessIdIn) {
    let businessId = undefined;
    if (!businessIdIn) {
      businessId = window.location.href.toString().split('home/')[1];
    }

    return axiosCalendarRequest.get(`/signer-reviewers/get-pending-documents-by-company/${businessId ? businessId : businessIdIn}`, { headers: authHeader() });
  }

  getSignatureActivitiesCurrentUser() {
    const { userId } = decodeToken(sessionStorage.getItem('access_token'));
    return axiosCalendarRequest.get(`/signer-reviewers/pending-documents-signature/${userId}`, { headers: authHeader() });
  }

  isSigned(documentId) {
    return axiosCalendarRequest.get(`/signer-reviewers/is-signed/${documentId}`, { headers: authHeader() });
  }

  async getAllFirms(documents) {
    const out = [];

    for (let i = 0; i < documents.length; i++) {
      const link = await axiosSignatureRequest.get(`/signature?documentId=${documents[i].documentId}`, { headers: authHeader() });
      out.push(link.data.documentUrl);
    }

    return out;
  }

  async findActivityById(id, success, error) {
    try {
      const activity = (await axiosCalendarRequest.get(`calendars/activity-by-id/${id}`, { headers: authHeader() })).data;
      let statusBpm = '';
      let rejected = false;
      let erasable = false;

      for (const document of activity.documents) {
        if (document.statusBpm === 'Rechazo de Firma') {
          statusBpm = 'Rechazo de Firma';
        }

        for (const signer of document.signerReviewer) {
          if (activity.type === 'revision') {
            try { //PARCHE del estatus cuando no se a tocado la actividad de revision
              // evaluar request
              const review = (await axiosCalendarRequest.get(`/signer-reviewers/validate-review-user/${document.documentId}/${signer.userId}`, { headers: authHeader() })).data;

              if (review) {
                erasable = true;
              }
            } catch (e) {
              error(e)
            }
          } else {
            if (signer.signed) {
              erasable = true;
            }

            if (signer.rejected) {
              rejected = true;
            }
          }
        }
      }

      success({
        ...activity,
        erasable,
        statusBpm,
        rejected
      });
      console.log({
        ...activity,
        erasable,
        statusBpm,
        rejected
      })
    } catch (errorResp) {
      error(errorResp);
    }

    return;
  }

  async checkReviewOrSign(documentId, success, error) {
    try {
      const review = await axiosRequestBlockChain.get(`/document/reviewers?documentId=${documentId}`, { headers: authHeader() });
      const user = review.data && review.data.Users
        ? review.data.Users.find(user => user.userId === sessionStorage.getItem('userId')) : null;

      const resp = {
        sign: false,
        review: user && !user.statusReview
      };

      axiosCalendarRequest.get(`/calendars/activity-by-document-id/${documentId}`, { headers: authHeader() })
        .then(({ data }) => {
          data.forEach(item => {
            const doc = item.documents.find(document => document.documentId === documentId
              && document.statusBpm !== 'Rechazo de Firma'
              && document.signerReviewer.some(signer => signer.userId === sessionStorage.getItem('userId')
                && signer.type === 'firm'));

            const signer = doc ? doc.signerReviewer.find(signer => signer.userId === sessionStorage.getItem('userId')
              && signer.type === 'firm') : null;
            resp.sign = resp.sign ? resp.sign : signer !== null && !signer.signed;
          });

          success(resp);
        }).catch((errorResp) => {
          error(errorResp);
        })

    } catch (err) {
      error(err)
    }
  }

  async checkSign(documentId, success, error) {
    const review = await axiosRequestBlockChain.get(`/document/reviewers?documentId=${documentId}`, { headers: authHeader() });
    const user = review.data && review.data.Users
      ? review.data.Users.find(user => user.userId === sessionStorage.getItem('userId')) : null;

    const resp = {
      sign: false,
      review: user && !user.statusReview
    };

    axiosCalendarRequest.get(`/calendars/activity-by-document-id/${documentId}`, { headers: authHeader() })
      .then(({ data }) => {

        let userFirm = data.filter((user) => user.type == "firm");

        success(userFirm);
      }).catch((errorResp) => {
        error(errorResp);
      })
  }

  getInfoActivityById(documentId, success, error) {
    axiosRequestBlockChain.get(`/calendars/activity-by-document-id/${documentId}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(errorResp => error(errorResp))
  }

  getActivityTypeByDocumentId(documentId, type, success, error) {
    axiosCalendarRequest.get(`/calendars/activity-by-documentId-and-type/${documentId}/${type}`, { headers: authHeader() })
      .then(({ data }) => success(data))
      .catch(err => error(err))
  }

  getGoogelAccess() {
    return axiosCalendarRequest.get(`/google-calendar/synchronize-calendar`, { headers: authHeader() });
  }

  hasGoogleCredentials() {
    return axiosCalendarRequest.get('/google-calendar/user-has-credentials', { headers: authHeader() });
  }

  syncCalendar(token) {
    return axiosCalendarRequest.post(`/google-calendar/get-code-save-credentials`, { token }, { headers: authHeader() });
  }

  getOutlookPermissions() {
    return axiosCalendarRequest.get(`/outlook-calendar/get-permission`, { headers: authHeader() });
  }

  getOutlookSync(code) {
    return axiosCalendarRequest.get(`/outlook-calendar/sync-calendar/${code}`, { headers: authHeader() });
  }

}
