//React
import { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
//Material UI
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Grid,
} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
// import moment from "moment-timezone";
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
// axeleratum
import {
	uuid,
	Alert,
	// DocumentPdfViewer,
} from "axeleratum-sgc-frontend-library";
//Components
import { DocumentPdfViewer } from "../../from-library/document-pdf-viewer/DocumentPdfViewer";
import DocumentReview from "../../vault/document-review";
import { ConfirmDialog } from "../../control/calendar/sections/confirm-dialog";
import { CalendarHttp } from "../../../core/http/calendar.http";
import { AlertComponent } from "../../AlertComponent";
import { DialogComponent } from "../../control/calendar/forms/new-activity/signature/DialogComponent";
import { ReviewButton } from "../../control/calendar/forms/review-button";
import { FirmButton } from "../../control/calendar/forms/firm-button";
// import { Reminder } from "../../control/notification/reminder";
import { ReminderFunc } from "../../control/notification/ReminderFunc";
import { Rejected } from "../../control/notification/rejected";
import { DetailsModal } from "../../vault/DetailsModal";
import { DocumentsHttp } from "../../../core/http/documents.http";
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { PDFDocument } from "pdf-lib";

//Scripts - Funciones - Otros
import saveAs from "file-saver";
import {
	validatePermissions,
	permissionScheme,
} from "../../../core/utils/can-i";
import { cancelSignatureProccess, downloadSignaturesHistory, editExpirationDate } from "../../../core/http/functionRequests/signature.http";
import { getCurrentUser } from "../../../core/helpers";
import { SignatureNotificationConfig } from "../../control/calendar/forms/new-activity/signature/SignatureNotificationConfig";
import { useDispatch } from "react-redux";
import { authActions } from "../../../core/actions";

const useStyles = makeStyles((theme) => ({
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	dialogActivity: {
		padding: "8px",
	},
	actions: {
		padding: "24px 24px",
	},
	cancelado: {
		color: "red",
		fontWeight: "bold"
	},
	firmadoDesc: {
		color: '#2a4298',
		fontWeight: 'bold !important'
	},
	margin: {
		marginBottom: "8px",
		marginLeft: "10px",
		width: "15px"
	},
}));

const expirationDates = {
	'12h': "Cada 12 horas",
	'1d': "Cada 1 día",
	'2d': "Cada 2 días",
	'3d': "Cada 3 días",
	'4d': "Cada 4 días",
	'5d': "Cada 5 días",
	'6d': "Cada 6 días",
	'7d': "Cada 7 días",
};

const documentsHttp = new DocumentsHttp();
const calendarHttp = new CalendarHttp();
const isBlock = { value: false };

export const DialogSignatureStatus = (props) => {
	const { item, onExitDetail, open, setOpen, onUpdate = () => { } } = props;
	const classes = useStyles();

	const [documentId, setDocumentId] = useState(null);
	const [documentName, setDocumentName] = useState(null);
	const [documentReview, setDocumentReview] = useState(null);
	const [documentIdOpen, setDocumentIdOpen] = useState(null);
	const [enableReview, setEnableReview] = useState(false);
	const [enableSign, setEnableSign] = useState(false);
	const [filePdf, setFilePdf] = useState("");
	const [loadFile, setILoadFile] = useState(false);
	const [openPdf, setOpenPdf] = useState(false);
	const [pdfPages, setPdfPages] = useState(0);
	const [openAlert, setOpenAlert] = useState(false);
	const [openConfirmAlert, setOpenConfirmAlert] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [openReminder, setOpenReminder] = useState(false);
	const [openDocumentReview, setOpenDocumentReview] = useState(false);
	const [openRejecteds, setOpenRejecteds] = useState(false);
	const [openLoader, setOpenLoader] = useState(false);
	const [title, setTitle] = useState("");
	const [titlePdf, setTitlePdf] = useState("");
	const [type, setType] = useState("");
	const [signDocumentsIsEnabled, setSignDocumentsIsEnabled] = useState(false);
	const [deleteActivityEnabled, setDeleteActivityEnabled] = useState(true);
	const [canceled, setCanceled] = useState(item.documents[0].document.StatusBpm === "Flujo de firma cancelado");
	const [showFirmButton, setShowFirmButton] = useState(true);
	const [openExpirationEdit, setOpenExpirationEdit] = useState(false);
	const [showHistoryDownload, setShowHistoryDownload] = useState(false);
	const currentDocument = item.documents[0];
	const signedDocument = [
		"Aprobado Con Firma",
		"Endosado con Firmas",
		"Cancelado",
		"Con Texto o Firma Adicional",
		"En Firmas de Endoso",
		"En Firmas para Cancelar",
		"En Firmas de Texto o Firma Adicional"
	].includes(currentDocument.document.StatusBpm)

	const item2 = item.documents[0]
	const dispatch = useDispatch();


	const handleOpenModal = () => {
		setOpenModal(true);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		setDocumentId(null);
	};

	const getPermissions = () => {
		const { userId, authorities } = getCurrentUser();
		if (!canceled) {
			if (item.username !== userId && !authorities.includes("Seguimiento a actividades generadas por otros")) {
				setDeleteActivityEnabled(false)
			}
		}
		validatePermissions(permissionScheme.documentManagement.signDocuments).then(
			(enabled) => {
				setSignDocumentsIsEnabled(enabled);
			}
		);
	};

	const validateSignatureAuth = () => {
		const userId = sessionStorage.getItem('userId');
		const signers = currentDocument.signerReviewer;
		setShowHistoryDownload(signers.some(el => el.hasVerificationId))

		const userSigner = signers.find(el => el.userId === userId);
		if (userSigner?.signType === 'link' && !userSigner?.signed) {
			setShowFirmButton(false)
		}
	}

	const handleDownloadPdf = async (data, extension) => {
		try {
			setILoadFile(false);
			if (extension.toLowerCase() === ".pdf") {
				const url = URL.createObjectURL(
					new Blob([data], {
						type: "application/pdf",
					})
				);
				const pdfDoc = await PDFDocument.load(data)
				const pages = pdfDoc.getPages()
				setPdfPages(pages.length)
				setFilePdf(url);
				setTitlePdf(`${currentDocument.documentName}${extension}`);
				setOpenPdf(true);
				setDocumentIdOpen(documentId);
			} else {
				const blob = new Blob([data], {
					type: "application/octet-stream",
				});
				saveAs(blob, `${currentDocument.documentName}${extension}`);
			}
		} catch (error) {
			console.error(error);
		}
	}

	const openDocument = (documentId) => {
		setILoadFile(true);

		checkReviewOrSign(documentId);

		documentsHttp.downloadDocumentById(
			documentId,
			(resp, extension) => handleDownloadPdf(resp.data, extension),
			(error) => {
				if (error.response.status === 401) {
					dispatch(authActions.userLoggedOut())
				}
				setTitle("Ocurrió un error al abrir documento. Intente más tarde.");
				setType("error");
				setOpenAlert(true);
				setILoadFile(false);
			}
		);
	};

	const handleReview = (item) => {
		setDocumentReview({ id: item.documentId, name: item.documentName });
		setDocumentReview(true);
	};

	const checkReviewOrSign = (documentId) => {
		calendarHttp.checkReviewOrSign(
			documentId,
			(resp) => {
				setEnableReview(resp.review);
				setEnableSign(resp.sign);
			},
			(error) => {
				if (error.response.status === 401) {
					dispatch(authActions.userLoggedOut())
				}
			}
		);
	};

	const handleUpdateExpiration = async (activityId, body) => {
		try {
			await editExpirationDate(activityId, body);
			onUpdate()
			setTitle("Modificación realizada correctamente")
			setType("success")
			setOpenAlert(true)
		} catch (error) {
			console.error(error);
			if (error.response.status === 401) {
				dispatch(authActions.userLoggedOut())
			}
			setTitle("Error editando las notificaciones")
			setType("error")
			setOpenAlert(true)
		} finally {
			setOpenExpirationEdit(false)
		}
	}

	const handleDownloadHistory = async () => {
		try {
			const id = item2.documentId;
			const response = await downloadSignaturesHistory(id)
			const blob = new Blob([response])
			const url = window.URL.createObjectURL(blob);
			const a = document.createElement('a');

			a.href = url;
			a.download = 'Historial.pdf';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			window.URL.revokeObjectURL(url)
		} catch (error) {
			console.error(error);
		}
	}

	useEffect(() => {
		getPermissions();
		validateSignatureAuth()
	}, [item]);


	return (
		<>
			<Dialog className={classes.dialogActivity} open={open} maxWidth={"md"}>
				{item.type === "firm" || item.type === "textsigner" ? <DialogTitle>Estatus de Firma</DialogTitle> : <></>}
				{item.type === "endorse" ? <DialogTitle>Estatus de Endoso</DialogTitle> : <></>}
				{item.type === "cancel" ? <DialogTitle>Estatus de Cancelación</DialogTitle> : <></>}
				<DialogContent>
					<Grid container direction="row" justifyContent="center" spacing={2}>
						<Grid
							container
							item
							direction="row"
							justifyContent="center"
							alignItems="center">
							<Grid item xs={12}>
								{currentDocument && (
									<>
										<span className="fa fa-file primary-text fa-2x mr-2" />
										<button
											title={currentDocument.documentName}
											className="link"
											onClick={() => openDocument(currentDocument.documentId)}>
											{loadFile && <i className="fa fa-spinner fa-spin"></i>}
											{currentDocument.documentName}
										</button>
									</>
								)}
							</Grid>
						</Grid>

						<Grid item container spacing={0}>
							<Grid container item alignItems="center">
								<Grid item xs={3}>
									<p style={{ color: "#5C7BF0" }}>Actividad</p>
								</Grid>
								<Grid item xs={6}>
									<p>{item.name}</p>
								</Grid>
								<Grid item container xs={3} justifyContent="flex-end">
									<Grid item>
										{
											(item.type === 'firm' &&
												!item.firmamexSetId &&
												deleteActivityEnabled &&
												!signedDocument &&
												!canceled) && (
												<Button
													onClick={() => setOpenConfirmAlert(true)}
													color="primary"
													variant="contained">
													Cancelar Flujo
												</Button>
											)
										}
										{canceled && (
											<span className={classes.cancelado}>CANCELADO</span>
										)}
									</Grid>
								</Grid>
							</Grid>
							<Grid item container alignItems="center">
								<Grid item xs={3}>
									<p style={{ color: "#5C7BF0" }}>Descripción</p>
								</Grid>
								<Grid item xs={9}>
									<p>{item.description}</p>
								</Grid>
							</Grid>
						</Grid>

						<Grid container item>
							<Grid item xs>
								<TableContainer>
									<Table size="small" aria-label="a dense table">
										<TableHead>
											<TableRow>
												<TableCell>
													<strong>Trazabilidad</strong>
												</TableCell>
												{
													showHistoryDownload ?
														<TableCell>
															<strong>Historial</strong>
														</TableCell>
														: <></>
												}
												<TableCell>
													<strong>Invitados</strong>
												</TableCell>

												<TableCell>
													<strong>
														{["firm", "endorse", "cancel", "textsigner"].includes(item.type) ? "Firmado" : "Revisión"}
													</strong>
												</TableCell>

												<TableCell>
													<strong>Creado</strong>
												</TableCell>

												<TableCell>
													<strong>Recordatorio, Expira</strong>
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											<TableRow>
												<TableCell>
													<Button
														color="primary"
														variant="contained"
														onClick={() => {
															const [currentDocument] = item.documents;
															const { documentId, documentName } =
																currentDocument;
															setDocumentId(documentId);
															setDocumentName(documentName);
															handleOpenModal();
														}}>
														Ver
													</Button>
												</TableCell>
												{
													showHistoryDownload ?
														<TableCell>
															<Button
																color="primary"
																variant="contained"
																onClick={handleDownloadHistory}>
																Descargar
															</Button>
														</TableCell>
														: <></>
												}

												<TableCell>
													{openReminder && (
														<Dialog
															className="notification-dialog"
															open={openReminder}>
															<ReminderFunc
																canceled={canceled}
																users={item2.signerReviewer}
																documentId={item2.documentId}
																activity={item}
																onClose={() => {
																	setOpenReminder(false);
																	onUpdate();
																}}
																hasOrder={item2.withOrder}
																hasNom151={item.hasNom151}
																expired={item.expiration_date && moment.duration(new moment().diff(item.expiration_date)) > 0}
															/>
														</Dialog>
													)}

													{openRejecteds && (
														<Dialog
															className="notification-reject"
															open={openRejecteds}>
															<Rejected
																users={item2.signerReviewer}
																onClose={() => setOpenRejecteds(false)}
															/>
														</Dialog>
													)}

													{item2.signerReviewer.map((user, index) => (
														<div
															className={`${index >= 3 ? "" : "col-xs-1"}`}
															key={uuid()}
															onClick={() =>
																["Rechazo de Firma", "Rechazo de Endoso"].includes(item.statusBpm)
																	? setOpenRejecteds(true)
																	: ["firm", "endorse", "cancel", "textsigner"].includes(item.type) &&
																	setOpenReminder(true)
															}>
															{index >= 3 ? undefined : index == 2 ? (
																<div
																	style={{
																		marginLeft: `-${8 * index}px`,
																		background: user.color,
																	}}
																	className="text-center circle-user">
																	{item2.signerReviewer.length + 1 - 3}+
																</div>
															) : (
																<div
																	style={{
																		marginLeft: `-${8 * index}px`,
																		background: user.color,
																	}}
																	className="text-center circle-user"
																	title={user.name}>
																	{user.name.toString().substring(0, 1)}
																</div>
															)}
														</div>
													))}
												</TableCell>

												<TableCell>
													{item.type == "firm" && showFirmButton ? (
														<FirmButton
															item={item2}
															disabledButton={
																!signDocumentsIsEnabled || canceled
															}
														/>
													) : item.type == "revision" ? (
														<ReviewButton
															item={item2}
															onClick={() => {
																handleReview(item2);
															}}
														/>
													) : (
														<p className={classes.firmadoDesc}>
															Firmar via correo
														</p>
													)}
												</TableCell>

												<TableCell>
													{item.date
														? new Date(
															item.date + "GMT-6"
														).toLocaleDateString("es-Mexico", {
															year: "numeric",
															month: "short",
															day: "numeric",
														})
														: "-"}
												</TableCell>

												<TableCell>
													{item.remind_every
														? `${expirationDates[item.remind_every]}, el `
														: "-"}

													{item.expiration_date &&
														moment.duration(
															new moment().diff(item.expiration_date)
														) > 0 ? (
														<span
															style={{ color: "red", fontWeight: "bold" }}>
															{
																new Date(
																	item.expiration_date + "GMT-6"
																).toLocaleDateString("es-Mexico", {
																	year: "numeric",
																	month: "short",
																	day: "numeric",
																}
																)
															}
														</span>



													) : item.expiration_date ? (
														new Date(
															item.expiration_date + "GMT-6"
														).toLocaleDateString("es-Mexico", {
															year: "numeric",
															month: "short",
															day: "numeric",
														})
													) : (
														""
													)}

													{
														!(item.type === 'firm' && item.firmamexSetId) &&
														!canceled &&
														!signedDocument && (
															<IconButton
																className={classes.margin}
																size="small"
																color="primary"
																onClick={() => setOpenExpirationEdit(true)}>
																<EditIcon />
															</IconButton>
														)
													}
												</TableCell>
											</TableRow>
										</TableBody>
									</Table>
								</TableContainer>
							</Grid>
						</Grid>

						{canceled && (
							<Grid item container alignItems="center">
								<Grid item xs={3}>
									<p style={{ color: "#5C7BF0" }}>Motivo de cancelación</p>
								</Grid>
								<Grid item xs={9}>
									<p>{item.reason}</p>
								</Grid>
							</Grid>
						)}
					</Grid>
				</DialogContent>
				<DialogActions className={classes.actions}>
					<Grid container item justifyContent="flex-end" xs={12}>
						<Grid item>
							<Button
								variant="outlined"
								color="primary"
								onClick={() => {
									setOpen(false);
									onExitDetail && onExitDetail();
								}}>
								Cerrar
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
				<Backdrop open={openLoader} className={classes.backdrop}>
					<CircularProgress />
				</Backdrop>
			</Dialog>

			{openPdf && (
				<DocumentPdfViewer
					id={currentDocument.documentId}
					url={filePdf}
					pages={pdfPages}
					title={titlePdf}
					open={openPdf}
					onClose={() => {
						setOpenPdf(false);
						setEnableReview(false);
						setEnableSign(false);
					}}
					review={enableReview}
					onReview={() => {
						setDocumentReview({ id: documentIdOpen });
						setOpenDocumentReview(true);
					}}
					sign={enableSign}
					onSign={() => {
						calendarHttp
							.getFirm(documentIdOpen)
							.then(({ data }) => {
								window.open(data.documentUrl);
							})
							.catch((error) => {
								if (error.response.status === 401) {
									dispatch(authActions.userLoggedOut())
								}
								setType("error");
								setTitle("Tuvimos problemas para conectarnos con FirmaMex");
								setOpenAlert(true);
							});
					}}
				/>
			)}

			{documentId && openModal && (
				<DetailsModal
					documentId={documentId}
					documentName={documentName}
					openModal={openModal}
					handleCloseModal={handleCloseModal}
				/>
			)}

			{openDocumentReview && (
				<DocumentReview
					openDialog={openDocumentReview}
					onClose={() => {
						setOpenDocumentReview(false);
						isBlock.value = false;
					}}
					document={documentReview}
				/>
			)}

			{openExpirationEdit && (
				<SignatureNotificationConfig
					edit
					open={openExpirationEdit}
					onSave={(activityId, body) =>
						handleUpdateExpiration(activityId, body)
					}
					onCancel={() => setOpenExpirationEdit(false)}
					prev={item ?? {}}
					documentId={currentDocument.documentId}
				/>
			)}

			{openConfirmAlert && (
				<DialogComponent
					open={openConfirmAlert}
					title={"Cancelar flujo de firma"}
					setOpen={(value) => setOpenConfirmAlert(value)}
					textContent={
						<p>
							Al cancelar este flujo de firma, ya no se permitirá concluir con
							las firmas faltantes. <br />
							Se enviará una notificación a todos los firmantes que recibieron
							notificación de firma. <br />
							Una vez cancelado el flujo, ya no se puede reactivar.
						</p>
					}
					onConfirm={(reason) => {
						setOpenLoader(true);
						setOpenConfirmAlert(false);

						cancelSignatureProccess(item.documentId, {
							reason,
							activityId: item.id
						})
							.then((data) => {
								setOpenLoader(false);
								setOpenAlert(true);
								setTitle(data.message);
								setType("success");
								setCanceled(true);
								setOpen(false);
							})
							.catch((error) => {
								if (error.response.status === 401) {
									dispatch(authActions.userLoggedOut())
								}
								const msg = error.response.data.error
								setOpenLoader(false);
								setTitle(msg ?? "Error en el servidor");
								setType("error");
								setOpenAlert(true);
							});
					}}
					onCancel={() => {
						setOpen(false);
						onExitDetail && onExitDetail();
					}}
				/>
			)}

			{openAlert && (
				<AlertComponent
					open={openAlert}
					title={title}
					type={type}
					onConfirm={() => {
						setOpenAlert(false);
						// onExitDetail && onExitDetail();
					}}
				/>
			)}

			{/* {openAlert && (
				<Alert
					open={openAlert}
					title={message}
					onConfirm={() => setOpenAlert(false)}
					type={typeMessage}
				/>
			)} */}
		</>
	);
};
