import React, { Component } from 'react';
import { Field, reduxForm } from "redux-form";
import { RenderTextField, RenderMultiSelect, isMobileOnly } from 'axeleratum-sgc-frontend-library';
import { Button, Grid } from "@material-ui/core";
import { Alert } from "axeleratum-sgc-frontend-library";
import { allowStoreVaults } from '../../core/http/functionRequests';

class FormBusiness extends Component {

  constructor(props) {
    super(props);
    this.state = {
      availableCompaniesData: {},
      openSuccessMove: false,
      errorMessage: null,
    }
  }

  componentDidMount() {
    this.validateIfAllowSavecompanies();
  }

  validateIfAllowSavecompanies = async () => {
    try {
      const { data } = await allowStoreVaults()
      this.setState({
        availableCompaniesData: data,
        openSuccessMove: true,
        errorMessage: null
      });
    } catch (err) {
      this.setState({
        availableCompaniesData: {},
        openSuccessMove: true,
        errorMessage: 'Error al crear una nueva bóveda'
      });
    }
  }

  render() {
    const { availableCompaniesData, errorMessage, openSuccessMove } = this.state;
    const { handleSubmit, submitActions, users, tags, onCancel, initialValues } = this.props;

    if (errorMessage !== null) {
      return <Alert
        open={openSuccessMove}
        title={errorMessage}
        type="error"
        onConfirm={() => onCancel()}
      />;

    }

    if (!availableCompaniesData.available && !initialValues.users) {
      return <Alert
        open={openSuccessMove}
        title={`Ha llegado al límite de ${availableCompaniesData.quantityAvailable} bóvedas autorizadas; si requiere más, favor de contactarse a Käsiä Solutions. info@kasiasolution.com   Tel: +52 55 7973 2838`}
        type="error"
        onConfirm={() => onCancel()}
      />;
    }

    return (
      <form className={isMobileOnly ? 'p-3' : 'p-5'} style={{ overflowY: 'auto' }} onSubmit={handleSubmit(submitActions)}>
        <Grid container>
          <Grid item md={12}>
            <h3>
              {
                initialValues.users ?
                  'Editar Bóveda'
                  :
                  'Añadir Bóveda'
              }
            </h3>

            <Field
              label="Nombre de la bóveda"
              name="name"
              required
              component={RenderTextField}
            />

            <Field
              label="Agregar miembros"
              name="users"
              options={users}
              required
              component={RenderMultiSelect}
            />

            <Field
              label="Etiquetas"
              name="tags"
              options={tags}
              isClearable
              component={RenderMultiSelect}
            />

            <Grid className="mt-2" container justifyContent="flex-end">
              <Button
                type="button"
                variant="outlined"
                color="secondary"
                onClick={() => onCancel()}
              >
                Cancelar
              </Button>

              <Button
                className="ml-2"
                type="submit"
                variant="contained"
                color="primary"
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )
  }
}

const validate = (values) => {
  const errors = {
    name: ''
  }

  if (!values.name) {
    errors.name = 'El nombre de la bóveda es requerido';
  }

  if (values.name) {
    if (values.name > 100) {
      errors.name = 'El nombre de la bóveda no puede ser mayor a 100 caracteres';
    }
  }

  if (!values.folder) {
    errors.folder = 'El nombre de la bóveda es requerido';
  }

  return errors;
}

export default reduxForm({
  form: 'FormBusiness',
  validate
})(FormBusiness);
