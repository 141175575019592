

export const SelectedUsersSimbols = ({ users = [] }) => {
  return (
    <>
      {
        users.map((user, index) => (
          <div
            className={`${index >= 4 ? "" : "col-md-1 col-xs-1"}`}
            key={(user.userId ?? user.id) + Date.now()}
          >
            {index >= 4 ? undefined : index === 3 ? (
              <div
                style={{
                  marginLeft: `-${8 * index}px`,
                  background: user.color
                    ? user.color
                    : user.colorUser,
                }}
                className="text-center circle-user"
                onClick={() => { }}
              >
                {users.length + 1 - 4}+
              </div>
            ) : (
              <>
                {users.length === 1 ? (
                  <div
                    style={{
                      marginLeft: "-25px",
                      background: user.color
                        ? user.color
                        : user.colorUser,
                    }}
                    className="text-center circle-user"
                    title={user.name}
                    onClick={() => { }}
                  >
                    {user.name ? user.name.substring(0, 1) : ""}
                  </div>
                ) : (
                  <div
                    style={{
                      marginLeft: `-${8 * index}px`,
                      background: user.color
                        ? user.color
                        : user.colorUser,
                    }}
                    className="text-center circle-user"
                    title={user.name}
                    onClick={() => { }}
                  >
                    {user.name ? user.name.substring(0, 1) : ""}
                  </div>
                )}
              </>
            )}
          </div>
        ))}
    </>
  )
}
