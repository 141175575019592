import React from "react";
import {
  Card,
  CardContent,
  Grid,
  Box,
  Button
} from "@material-ui/core";
import "./card-user.scss";

export const CardUser = ({ index, name, email, onDelete, released }) => {
  return (
    <Grid item xs>
      <Card>
        <CardContent>
          <Grid container spacing={1} alignItems="center" justifyContent="space-between">
            <Grid item xs>
              <Box display={"flex"} >
                {
                  index && (
                    <div style={{ display: 'flex', alignItems: "center" }}>
                      <span
                        style={{ padding: '0px 10px 0px 0', color: "#5C7BF0", fontWeight: "bold" }}
                      >
                        {index}
                      </span>
                    </div>
                  )
                }
                <div className={`pl-3`}>
                  <span title={name}>{name}</span>
                  <br />
                  <em className="primary-text" title={email}>
                    {email}
                  </em>
                </div>
              </Box>
            </Grid>
            {
              !!onDelete && (
                <Grid item>
                  {
                    released
                      ? <span><stron>Liberado</stron></span>
                      : (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={onDelete}
                        >
                          Eliminar
                        </Button>
                      )

                  }
                </Grid>
              )
            }
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )
}
