import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { unregister } from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './configs/redux.config';
import { HashRouter } from 'react-router-dom';
import 'axeleratum-sgc-frontend-library/styles/index.css';
import 'react-calendar/dist/Calendar.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './styles/bootstrap.css';
ReactDOM.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>
  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
unregister();
