import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@material-ui/lab/Alert";
import { UserHttp } from '../../../core/http/user.http';
import { tokenExpired } from "../../../core/utils";
import { authActions } from "../../../core/actions";
import "./popup-user.scss";
import { CardUser } from './CardUser';
import { UserSelector } from './UserSelector';

const PopUpNoSignersSelect = ({ open, currentSigners, noSignersSelected, onSubmit, onCancel }) => {

  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.authReducer.loggedIn);

  const [usersSelected, setUsersSelected] = useState(noSignersSelected);
  const [availableUsers, setAvailableUsers] = useState([]);
  const [openSelectUsers, setOpenSelectUsers] = useState(false);
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = () => {
    onSubmit && onSubmit(usersSelected);
  }

  const handleCancel = () => {
    onCancel();
  }

  useEffect(() => {

    if (isLoggedIn && tokenExpired()) {
      dispatch(authActions.userLoggedOut())
      return
    }


    const userHttp = new UserHttp();
    setLoad(true);
    userHttp.findAllUsersByDocumentId(
      null,
      "external",
      (resp) => {
        const sortUsersName = (x, y) => {
          return x.name.localeCompare(y.name);
        };
        const filtered = resp.filter(el => !currentSigners.find(signer => signer.id === el.id))
        let s = filtered.sort(sortUsersName);
        setAvailableUsers(s)
        setLoad(false);
      },
      (error) => {
        setLoad(false);
        setError(true);
      }
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Dialog className="popup-user" open={open}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: "10px" }}>
          <DialogTitle>Destinatarios seleccionados</DialogTitle>
          {
            !load && (
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenSelectUsers(true)}
              >
                Agregar
              </Button>
            )
          }
        </div>

        {error && (
          <DialogContent>
            <Alert severity="error">
              Hubo un problema al cargar lo usuarios inténtalo mas tarde
            </Alert>
          </DialogContent>
        )}

        {load ? (
          <DialogContent className="text-center">
            <CircularProgress />
          </DialogContent>
        ) : (
          <>
            {!error && (
              <>
                <DialogContent className="card-zone">
                  <Grid
                    container
                    spacing={1}
                    direction="column"
                  >
                    {usersSelected.map(user => (
                      <CardUser key={user.id} {...user} />
                    ))}
                  </Grid>

                  {usersSelected.length === 0 && (
                    <div className="text-center mt-2">
                      No hay usuarios seleccionados
                    </div>
                  )}
                </DialogContent>
              </>
            )}
          </>
        )}
        <div>
          <DialogActions>

            <Button
              className="primary-text"
              color="primary"
              variant="outlined"
              onClick={() => handleCancel()}
            >
              Cancelar
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSubmit()}
            >
              Aceptar
            </Button>

          </DialogActions>
        </div>
      </Dialog>

      {
        openSelectUsers ? (
          <UserSelector
            open={openSelectUsers}
            users={availableUsers}
            setUsers={setAvailableUsers}
            selected={usersSelected}
            currentSigners={currentSigners}
            onSelect={setUsersSelected}
            onClose={() => setOpenSelectUsers(false)}
            allowExternal
          />
        ) : <></>
      }

    </>
  )
}

export default PopUpNoSignersSelect;
