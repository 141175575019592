import {
  authConstants
} from '../constants';

import { getCurrentUser } from '../helpers'

import initialState from '../../configs/intialState.config';

export function authReducer(state = initialState.session, action) {
  switch (action.type) {

    case authConstants.REFRESH_TOKEN_SUCESS:
    case authConstants.LOGIN_SUCCESS:
      const { payload } = action;

      if (payload) {
        const {
          access_token,
          clientId,
          clientKey,
          userId,
        } = payload;
        sessionStorage.setItem('access_token', access_token)
        sessionStorage.setItem('userId', userId)
        sessionStorage.setItem('clientId', clientId)
        sessionStorage.setItem('clientKey', clientKey)
        localStorage.setItem('clientKey', clientKey)

        return {
          ...state,
          loggedIn: true,
          currentUser: getCurrentUser(access_token)
        }
      }
      const currentToken = sessionStorage.getItem('access_token')
      if (currentToken) {
        return {
          ...state,
          loggedIn: true,
          currentUser: getCurrentUser(currentToken)
        }
      }

      return {
        ...state,
      }

    case authConstants.RESTORE_PASSWORD_FAILURE:
    // case authConstants.SENT_EMAIL_PASSWORD:
    case authConstants.SEND_EMAIL_PASSWORD_FAILURE:
    case authConstants.SIGNUP_SUCCESS:
    case authConstants.SIGNUP_FAILURE:
    case authConstants.LOGIN_FAILURE:
    case authConstants.RESTORE_PASSWORD_SUCCESS:
      return {
        ...state
      }

    case authConstants.LOGOUT:
      return {
        loggedIn: false,
        keep_active_session: false,
        currentUser: null
      }

    case authConstants.ACTIVE_SESSION:
      return {
        ...state,
        keep_active_session: action.payload.keep_active_session
      }

    case authConstants.USER_LOGGED_OUT:
      return {
        ...state,
        loggedIn: false
      }

    default:
      return state;

  }
}
