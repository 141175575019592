import { decodeToken } from '../utils'

export const getCurrentUser = (token) => {

  if (!token && !sessionStorage.getItem('access_token')) {
    return null
  }

  const decodedToken = decodeToken(token || sessionStorage.getItem('access_token'))

  const {
    email,
    authorities,
    clientId,
    availableSignatures,
    reviewAlwaysRequired,
    defaultStroke,
    showIdMgmtButton,
    hasBusinessMgmt,
    allowScannedCopy,
    onlyNom151,
    hasNom151,
    signatureWithNomDefault,
    userId,
    monthsExpirationDefault,
    showBlockSignatureButton,
    storageProvider,
    clientKey,
    encryptedStorage,
    name,
    loginSSO,
    endorsmentWithSignature,
    allowDocumentSet,
    idMgmt,
  } = decodedToken

  return {
    user_name: email,
    authorities,
    client_id: clientId,
    userId,
    completename: name,
    reviewAlwaysRequired,
    availableSignatures: availableSignatures ? availableSignatures : [],
    defaultStroke: defaultStroke ?? "any",
    showIdMgmtButton,
    hasBusinessMgmt,
    allowScannedCopy,
    onlyNom151,
    hasNom151,
    signatureWithNomDefault,
    monthsExpirationDefault,
    showBlockSignatureButton: showBlockSignatureButton ?? false,
    storageProvider,
    clientKey,
    encryptedStorage,
    loginSSO: loginSSO ?? false,
    endorsmentWithSignature: endorsmentWithSignature ?? false,
    allowDocumentSet: allowDocumentSet ?? false,
    idMgmt: idMgmt ?? false
  }
}