import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userConstants } from "../core/constants";
import { getVaultsUser } from "../core/http/functionRequests";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { authActions } from "../core/actions";



export const useCompaniesUser = () => {

  const params = useParams();
  const companies = useSelector(state => state.userReducer.companies);
  const fetchingCompanies = useSelector(state => state.userReducer.fetchingCompanies);
  const dispatch = useDispatch();



  const getCompanies = async () => {
    try {
      const { vaultId } = params;
      dispatch({
        type: userConstants.FETCHING_COMPANIES,
      });

      const response = await getVaultsUser();

      dispatch({
        type: userConstants.SET_AVAILABLE_COMPANIES,
        payload: vaultId ? response?.data?.filter(el => el.id !== vaultId) : response?.data
      });

    } catch (error) {
      console.error(error);
      if (error.response.status === 401) {
        dispatch(authActions.userLoggedOut())
      }
    }
  }

  useEffect(() => {
    getCompanies();
    // eslint-disable-next-line
  }, []);


  return {
    companies,
    fetchingCompanies,
  }

}