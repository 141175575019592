import { businessMgmtInstance, authHeader } from "../../../configs/axios.config"



export const createSubChart = (chartId, body) => businessMgmtInstance({
  method: "POST",
  url: `/chart/${chartId}`,
  data: body,
  headers: authHeader()
})


export const updateSpreadCheetChart = (chartId, body) => businessMgmtInstance({
  method: "PUT",
  url: `/chart/${chartId}`,
  data: body,
  headers: authHeader()
})

export const deleteSpreadSheetChart = (spreadSheetId) => businessMgmtInstance({
  method: "DELETE",
  url: `/chart/${spreadSheetId}`,
  headers: authHeader()
})


export const getSpreadSheetChart = (id, fileName) => businessMgmtInstance({
  url: `/chart/${id}/${fileName}`,
  headers: authHeader()
})

export const getChartById = (id) => businessMgmtInstance({
  url: `/chart/${id}`,
  headers: authHeader()
})

export const getAllBusiness = () => businessMgmtInstance({
  url: '/business',
  headers: authHeader()
})


export const getBusinessById = (businessId) => businessMgmtInstance({
  url: `/business/${businessId}`,
  headers: authHeader()
})


export const createBusiness = (body) => businessMgmtInstance({
  method: 'POST',
  url: '/business',
  data: body,
  headers: authHeader()
})


export const editBusiness = (businessId, body) => businessMgmtInstance({
  method: 'PUT',
  url: `/business/${businessId}`,
  data: body,
  headers: authHeader()
})
